import React, {Suspense} from 'react';
import './App.scss';

import {useTranslation} from 'react-i18next';
import AppRouter from "./AppRouter";


export const joinUsBaseURL="http://jobmarkettest.mateda.hu/jobmarket"
export const joinUsLink = joinUsBaseURL + "/premarketing/worker";
function Spinner() {
    return <div></div>
}

function Main() {
    const [t, i18n] = useTranslation();
    const nextLang = i18n.language ===  'en' ? 'hu' :'en';
    return (
        <div>
            <AppRouter/>
            {/*<div className="container">
                <TestPage/>
            </div>*/}
        </div>
    )
}

function App() {

    return (
        <Suspense fallback={<Spinner/>}>
            <div className="App">
                <Main/>
            </div>
        </Suspense>
    );
}

export default App;
