import React, {useContext, useState} from 'react';
import {AppContext} from "../AppRouter";
import {usePrevious} from "../hooks";
import classNames from 'classnames';

export const AppNotificationContext = React.createContext({});

export function AppNotificationProvider(props) {
    const [notification, setNotification] = useState(null);

    const showNotification = (notification) => {
        setNotification(notification);
    };

    const clear = () => setNotification(null);
    return (
        <AppNotificationContext.Provider value={{notification, setNotification, clear, show: showNotification}}>
            {props.children}
        </AppNotificationContext.Provider>
    )
}

export default function AppNotification() {
    const {notification, clear, setNotification} = useContext(AppNotificationContext);
    const appContext = useContext(AppContext);
    const prevLocation = usePrevious(appContext.routerProps.location);


    if (prevLocation !== appContext.routerProps.location) {
        clear();
    }

    if (!notification) return null;

    return (
        <div className="app-notification">
            <div className="app-notification-items" onClick={clear}>
                <div className={classNames("app-notification-item",{"is-error": notification.type === "error"})}>
                    {notification.message}
                </div>

            </div>
        </div>
    )
}