import React, {useContext, useEffect, useState} from 'react';
import {useAction, useLoadAction} from "../hooks";
import {
    ActionButton,
    Button,
    ErrorComp,
    Form,
    NumberField,
    RatingField,
    SelectField,
    TextAreaField,
    TextField,
    useForm
} from "../components/Form";
import dayjs from "dayjs";
import {Info, Map, UserCircle} from "../components/Icons";
import {useTranslation} from 'react-i18next';
import * as API from '../api';
import {addressToString} from "./JobsPage";
import {MessagesForJobApply} from "./MessagesPage";
import {Section} from "../components/other";
import {AppContext} from "../AppRouter";
import {MapComponent} from "../components/maps";
import {range} from "../utils";
import classNames from 'classnames';
import NumberFormat from 'react-number-format';

import Rating from "../components/Rating";
import LoadingIndicator from "../components/LoadingIndicator";

function createOptions(question, t) {
    if (question.values && question.values.length) {
        return question.values.map(val => ({
            value: val,
            label: t(question.identifier + "-" + question.type + "." + val)
        }))
    }
    return null;
}


function CategoryQuestion({question, field, readOnly}) {
    const [t] = useTranslation();

    const {identifier, type, helpDoc, values} = question;

    switch (type) {
        case 'number':
            return <NumberField {...field} readOnly={readOnly}/>;
        case 'select':
            return <SelectField {...field} options={createOptions(question, t)}/>;
        case 'truefalse':
            let trueLabelKey = question.identifier + "-" + question.type + ".true";
            const trueLabel = t(trueLabelKey);
            let falseLabelKey = question.identifier + "-" + question.type + ".false";
            const falseLabel = t(falseLabelKey);
            return <SelectField {...field}
                                emptyValue={""}
                                options={[
                                    {value: true, label: trueLabel != trueLabelKey ? trueLabel : t("yes")},
                                    {value: false, label: falseLabel != falseLabelKey ? falseLabel : t("no")}]}/>;
        case 'multiselect':
            return <SelectField {...field} options={createOptions(question, t)} isMulti={true}/>;
        case 'text':
            return <TextField {...field}/>;
    }
}


export function JobCategoryQuestions({questions, category, form, readOnly}) {
    const [t] = useTranslation();
    const fields = questions.reduce((res, question) => {
        const {identifier, helpDoc, type} = question;
        let fieldProps = {
            name: identifier,
            label: t(`${question.identifier}-${question.type}.label`),
           // help: helpDoc,
            required: true
        };

        res[identifier] = fieldProps;
        return res;
    }, {});

    return (
        <Form form={form}>
            {questions.map((question) => {
                if (readOnly && !form.values[question.identifier]) {
                    return null;
                }
                return <CategoryQuestion key={question.identifier}
                                         question={question}
                                         readOnly={readOnly}
                                         field={{...fields[question.identifier], readOnly}}/>
            })}
        </Form>

    )
}


export function DataHighlighter({title, data, info,className}) {
    return (
        <div className={classNames("data-highlighter",className)}>
            <div className="data-highlighter-title">
                {title}
                {info && <div className="tooltip is-tooltip-multiline" data-tooltip={info}><Info
                    className={classNames("icon info-icon is-right")}/></div>}
            </div>
            <div className="data-highlighter-data">
                {data}
            </div>
        </div>

    )
}


export function JobViewComponent({job, questions, showMessages, disableNewMessages, yourPrice}) {
    const [t] = useTranslation();
    const {user} = useContext(AppContext);
    const [showMap, setShowMap] = useState(false);
    const {category, startTime, jobStartDate, workTime, shortDescription, address, paymentMethod, phoneNumber, worker, computedPrice, computedHours} = job;

    const isWorker = job.worker ? job.worker.id === user.id : false;
    const isEmployer = job.requestUser ? job.requestUser.id === user.id : false;

    const requestUser = job.requestUser || user;

    const questionsForm = useForm(job.jobAnswers);

    useEffect(() => {
        window.scrollTo(0,0);
    },[])


    return (
        <div className="job-details-page">
            <div className="columns">
                {/*<div>Job:{JSON.stringify(job)}</div>*/}
                <div className="column">

                    <div className="job-details">
                        <div className="is-size-2 is-capitalized">{t("jobcategory." + category.id)}</div>

                        <div className="data-highlighter-container">
                            <div className="is-flex">
                                {job.jobRequestStateLabel && <DataHighlighter title="Munka állapota"
                                                                              data={job.jobRequestStateLabel}/>}

                                <DataHighlighter title={t('job.paymentState')}
                                                 data={job.paid ? t('job.paid') : ""}/>
                            </div>
                        </div>

                        <div className="data-highlighter-container">
                            <div className="is-flex">
                                <DataHighlighter title="Munkavégzés Kezdete:"
                                                 data={jobStartDate ? dayjs(jobStartDate).format("YYYY.MM.DD") + " " + startTime : ""}/>
                                {!job.id && <DataHighlighter title="Munka időtartama:"
                                                             data={Math.max((workTime || 0), (computedHours || 0)) + " " + t('hour')}/>}
                            </div>
                        </div>

                        <div className="data-highlighter-container">
                            <DataHighlighter title="Munkavégzés helye:" data={
                                <div className="job-address-text">
                                    {addressToString(address)}
                                    <span className="icon" onClick={() => setShowMap((showMap) => !showMap)}><Map
                                        title="Térkép mutatása"/></span>
                                    <div className="job-address">
                                        {showMap && <JobAddressComponent job={job}/>}
                                    </div>
                                </div>
                            }/>
                        </div>


                        <div className="data-highlighter-container">
                            <div className="is-flex">
                                <DataHighlighter className="payment-method" title="Fizetési mód:" data={t("paymentMethod." + paymentMethod)}/>
                                <DataHighlighter
                                    info={t(!isEmployer ? 'job.finalPrice_info' : 'job.computedPrice_info')}
                                    title={t(!isEmployer ? 'job.finalPrice' : 'job.computedPrice') + ":"}
                                    data={computedPrice ? (<div>
                                            <NumberFormat displayType={"text"} thousandSeparator={" "}
                                                                        suffix={" Forint"}
                                                                        value={computedPrice}/>
                                            <div className="help">{t('job.computedPrice_detail')}</div>
                                        </div>) :
                                        <span>{t("job.calculationInProgress")}</span>}/>
                            </div>

                        </div>


                        {!isEmployer && job.id && <div className="data-highlighter-container">
                            <div className="is-flex">
                                <DataHighlighter title={t('job.yourPrice') + ":"}
                                                 info={t('job.yourPrice_info')}
                                                 data={<NumberFormat displayType={"text"} thousandSeparator={" "}
                                                                     suffix={" Forint"}
                                                                     value={yourPrice}/>}/>
                            </div>

                        </div>}


                        <DataHighlighter title="Munka leírása:" data={shortDescription}/>

                    </div>


                </div>


                <div className="column">
                    {isWorker && <div className="requester">
                        <div className="title">
                            {t("job.requesterUser")}
                        </div>
                        <div className="profile">
                            <div className="profile-header">
                                <div className="profile-avatar">
                                    {!requestUser.profilePictureId ? <UserCircle/> :
                                        <img src={`/api/avatar/${requestUser.profilePictureId}`}/>}
                                </div>
                            </div>
                            <div className="profile-details">
                                <div>{requestUser.fullName}</div>
                                {/*<div className="is-small">{phoneNumber || user.phoneNumber}</div>*/}
                            </div>
                        </div>
                    </div>}

                    {isEmployer && <div className="worker">
                        <div className="title">
                            {t("job.worker")}
                        </div>
                        {!worker && <div>Még nem jelentkeztek a munkára</div>}
                        {worker &&
                        <div className="profile">
                            <div className="profile-header">

                                <div className="profile-avatar">
                                    {!worker.profilePictureId ? <UserCircle/> :
                                        <img src={`/api/avatar/${worker.profilePictureId}`}/>}
                                </div>
                            </div>
                            <div className="profile-details">
                                <div>{worker.fullName}</div>
                                {/*<div className="is-small">{worker.phoneNumber}</div>*/}
                                <Rating max={5} value={worker.workerValuationAverage}/>
                                <div className="job-count">{worker.workerJobsCount} munkát végzett el</div>
                            </div>
                        </div>}

                    </div>}
                </div>


            </div>
            <div className="columns">
                <div className="column">
                    <Section title={t("job.details")}>
                        <JobCategoryQuestions questions={questions}
                                              category={category}
                                              readOnly={true}
                                              form={questionsForm}/>
                    </Section>
                </div>
                <div className="column">
                    {showMessages && worker && <Section title={t("job.messages")}>
                        <MessagesForJobApply jobId={job.id} disableNewMessages={disableNewMessages}/>
                    </Section>}
                </div>
            </div>

        </div>

    )
}


function JobAddressComponent({job}) {
    if (!job.address.locAttitude || !job.address.locLongitude) {
        return null;
    }
    return (
        <MapComponent
            center={{
                lat: job.address.locAttitude,
                lng: job.address.locLongitude
            }}
            zoom={16}/>
    )
}


function FinishJobForm({job, loadJob, closeForm}) {
    const [t] = useTranslation();


    const jobValuationFormInitValues = {
        commentForEmployer: "",
        employerOfferOther: 0,
        employerPaying: 0,
        employerSecialEqip: 0,
        employerExperience: 0,
        employerTaskDescription: 0,
    }


    const form = useForm({});

    const jobValuationForm = useForm(jobValuationFormInitValues);

    const finalTime = {name: "finalTime", label: t("job.finalTime"), required: true};
    const finalTimeOptions = range(1, 16).map(hour => ({value: hour, label: hour + " " + t('hour'), required: true}));
    const finalPrice = {name: "finalPrice", label: t("job.finalPrice"), required: true};


    const commentForEmployer = {
        name: "commentForEmployer",
        label: t("jobValuation.commentForEmployer"),
        required: true
    };

    const employerOfferOther = {
        name: "employerOfferOther",
        label: t("jobValuation.employerOfferOther"),
        required: true
    }
    const employerPaying = {
        name: "employerPaying",
        label: t("jobValuation.employerPaying"),
        required: true
    }
    const employerSecialEqip = {
        name: "employerSecialEqip",
        label: t("jobValuation.employerSecialEqip"),
        required: true
    }
    const employerExperience = {
        name: "employerExperience",
        label: t("jobValuation.employerExperience"),
        required: true
    }
    const employerTaskDescription = {
        name: "employerTaskDescription",
        label: t("jobValuation.employerTaskDescription"),
        required: true
    }

    const finishJobAction = useAction(async () => {

        if (await form.validate() && await jobValuationForm.validate()) {
            await API.finishJob({jobId: job.id, ...form.values, jobValuation: jobValuationForm.values});
            await loadJob();
            closeForm();
        }

    });

    return (
        <div className="action-form-wrapper">

            <div className="cancel-job container">
                <Form form={form}>

                    <div className="buttons">
                        <ActionButton className="is-primary"
                                      action={finishJobAction}
                                      label={t('job.finished')}/>
                        <Button className="is-outlined"
                                onClick={closeForm}
                                label={t('cancel')}/>
                    </div>

                    <NumberField {...finalPrice} autoFocus={true}/>
                    <SelectField {...finalTime} options={finalTimeOptions}/>


                    <div className="job-valuation-form">
                        <div className="title is-4">{t('jobValuation.employerValuation')}</div>
                        <Form form={jobValuationForm} horizontal={true}>
                            <RatingField {...employerOfferOther}/>
                            <RatingField {...employerPaying}/>
                            <RatingField {...employerSecialEqip}/>
                            <RatingField {...employerExperience}/>
                            <RatingField {...employerTaskDescription}/>
                            <TextAreaField rows={3} {...commentForEmployer}/>
                        </Form>
                    </div>

                </Form>

            </div>
        </div>
    )
}


function CancelJobForm({job, loadJob, closeForm}) {
    const [t] = useTranslation();

    const form = useForm();
    const cancelReason = {name: "cancelReason", label: t("job.cancelReason"), required: true};
    const cancelJobAction = useAction(async () => {
        if (await form.validate()) {
            await API.cancelJob({jobId: job.id, cancelReason: form.values.cancelReason});
            await loadJob();
            closeForm();
        }

    });

    return (
        <div className="action-form-wrapper">
            <Form form={form} className="cancel-job container">
                <div className="buttons">
                    <ActionButton className="is-primary"
                                  action={cancelJobAction}
                                  label={t('job.cancel')}/>
                    <Button className="is-outlined"
                            onClick={closeForm}
                            label={t('cancel')}/>
                </div>

                <TextAreaField {...cancelReason} rows={5}/>

            </Form>
        </div>
    )
}


function JobValuationForm({job, initValues, loadJob, closeForm, children, buttonLabel, readOnly}) {

    const [t] = useTranslation();

    const form = useForm(initValues);

    const valuateJobAction = useAction(async () => {
        if (form.validate()) {
            try {
                await API.evaluateJob({jobId: job.id, valuation: form.values});
                await loadJob();
            } catch (e) {
                throw {error: t("jobValuation.error")}
            }

            closeForm();
        }
    });

    if (readOnly) {
        return <Form form={form} className="job-valuation-form">
            {children}
        </Form>
    }
    return (
        <div className="action-form-wrapper">
            <Form form={form} className="job-valuation-form container">

                <div className="buttons">
                    <ActionButton className="is-primary"
                                  action={valuateJobAction}
                                  label={buttonLabel}/>
                    <Button className="is-outlined"
                            onClick={closeForm}
                            label={t('cancel')}/>
                </div>

                <ErrorComp error={valuateJobAction.error}/>
                {children}
            </Form>
        </div>
    )
}


function JobValuationByEmployerForm({job, loadJob, closeForm, readOnly}) {
    const [t] = useTranslation();

    const initValues = job.jobValuation || {
            commentForWorker: "",
            workerOfferOther: 0,
            workerAttitude: 0,
            workerProfessional: 0,
            workerQuality: 0,
            workerPrecision: 0,
        }
    ;


    const commentForWorker = {
        name: "commentForWorker",
        label: t("jobValuation.commentForWorker"),
        required: true,
    };

    const workerOfferOther = {
        name: "workerOfferOther",
        label: t("jobValuation.workerOfferOther"),
        required: true
    }
    const workerAttitude = {
        name: "workerAttitude",
        label: t("jobValuation.workerAttitude"),
        required: true
    }
    const workerProfessional = {
        name: "workerProfessional",
        label: t("jobValuation.workerProfessional"),
        required: true
    }
    const workerQuality = {
        name: "workerQuality",
        label: t("jobValuation.workerQuality"),
        required: true
    }
    const workerPrecision = {
        name: "workerPrecision",
        label: t("jobValuation.workerPrecision"),
        required: true
    }


    return (
        <JobValuationForm job={job}
                          loadJob={loadJob}
                          closeForm={closeForm}
                          readOnly={readOnly}
                          initValues={initValues}
                          buttonLabel={t('jobValuation.workerValuation')}
        >

            <RatingField {...workerOfferOther} readOnly={readOnly}/>
            <RatingField {...workerAttitude} readOnly={readOnly}/>
            <RatingField {...workerProfessional} readOnly={readOnly}/>
            <RatingField {...workerQuality} readOnly={readOnly}/>
            <RatingField {...workerPrecision} readOnly={readOnly}/>
            <TextAreaField rows={3} {...commentForWorker} readOnly={readOnly}/>

        </JobValuationForm>
    )

}


function JobValuationByWorkerForm({job, loadJob, closeForm, readOnly}) {
    const [t] = useTranslation();
    const initValues = job.jobValuation || {
            commentForEmployer: "",
            employerOfferOther: 0,
            employerPaying: 0,
            employerSecialEqip: 0,
            employerExperience: 0,
            employerTaskDescription: 0,
        }
    ;

    const commentForEmployer = {
        name: "commentForEmployer",
        label: t("jobValuation.commentForEmployer"),
        required: true
    };

    const employerOfferOther = {
        name: "employerOfferOther",
        label: t("jobValuation.employerOfferOther"),
        required: true
    }
    const employerPaying = {
        name: "employerPaying",
        label: t("jobValuation.employerPaying"),
        required: true
    }
    const employerSecialEqip = {
        name: "employerSecialEqip",
        label: t("jobValuation.employerSecialEqip"),
        required: true
    }
    const employerExperience = {
        name: "employerExperience",
        label: t("jobValuation.employerExperience"),
        required: true
    }
    const employerTaskDescription = {
        name: "employerTaskDescription",
        label: t("jobValuation.employerTaskDescription"),
        required: true
    }


    return (
        <JobValuationForm job={job} loadJob={loadJob}
                          closeForm={closeForm}
                          readOnly={readOnly}
                          buttonLabel={t('jobValuation.employerValuation')}
                          initValues={initValues}>
            <RatingField {...employerOfferOther} readOnly={readOnly}/>
            <RatingField {...employerPaying} readOnly={readOnly}/>
            <RatingField {...employerSecialEqip} readOnly={readOnly}/>
            <RatingField {...employerExperience} readOnly={readOnly}/>
            <RatingField {...employerTaskDescription} readOnly={readOnly}/>
            <TextAreaField rows={3} {...commentForEmployer} readOnly={readOnly}/>

        </JobValuationForm>
    )
}

function ValuationsSection({job}) {
    const [t] = useTranslation();

    return (
        <Section title={t("job.valuations")} className="container">
            <div className="columns">
                <div className="column">
                    <div className="title">Megbízó értékelése</div>
                    <JobValuationByWorkerForm job={job} readOnly={true}/>
                </div>
                <div className="column">
                    <div className="title">Dolgozó értékelése</div>
                    <JobValuationByEmployerForm job={job} readOnly={true}/>
                </div>
            </div>

        </Section>)
}


export default function JobDetailsPage(props) {
    const [t] = useTranslation();

    const jobId = parseInt(props.match.params.id);
    const loadJobAction = useLoadAction(async () => {
        const job = await API.fetchJob({jobId});
        const result = await API.fetchCategoryQuestions({categoryId: job.request.category.id});

        return {job: job.request, questions: result.questions, yourPrice: job.yourPrice};
    });


    const acceptJobAction = useAction(async () => {
        await API.acceptJob({jobId});
        await loadJobAction.run();
    });

    const startPaymentAction = useAction(async () => {
        try {
            let result = await API.startPayment(jobId);
            if (result.paymentURL) {
                window.location = result.paymentURL;
            }
        } catch (e) {
            throw {error: t('job.paymentFailed')};
        }

    });

    const [showCancelJobForm, setShowCancelJobForm] = useState(false);
    const [showFinishJobForm, setShowFinishJobForm] = useState(false);
    const [showJobValuationForm, setShowJobValuationForm] = useState(false);

    const {user, routerProps} = useContext(AppContext)

    if (loadJobAction.isLoading) {
        return <LoadingIndicator/>;
    }


    if (!loadJobAction.result) return null;

    const job = loadJobAction.result.job;
    const questions = loadJobAction.result.questions;
    const yourPrice = loadJobAction.result.yourPrice;

    const isWorker = job.worker ? job.worker.id === user.id : false;
    const isEmployer = job.requestUser ? job.requestUser.id === user.id : false;
    const showCancelButton = ((job.requestState == 'applied' && (isWorker || isEmployer)) || (job.requestState) == 'started' && isEmployer);

    const isActionFormActive = showCancelJobForm || showFinishJobForm || showJobValuationForm;
    console.log({routerProps});
    return (
        <div className="job-page page">
            {!isActionFormActive &&
            <div className="buttons container">

                <button className="button" onClick={() => routerProps.history.goBack()}>{t('back')}</button>

                {job.requestState == "started" && !isEmployer && <ActionButton className="is-primary"
                                                                               action={acceptJobAction}
                                                                               label="Munka elfogadása"/>}

                {showCancelButton && <Button className="is-primary"
                                             label={t('job.cancel')}
                                             onClick={() => setShowCancelJobForm(true)}/>
                }
                {isWorker && job.requestState == 'applied' && <Button className="is-primary"
                                                                      label={t('job.finished')}
                                                                      onClick={() => setShowFinishJobForm(true)}/>}

                {job.requestState == 'finished' && ((isWorker && !job.valuatedByWorker) || (isEmployer && job.valuatedByWorker && !job.valuatedByEmployer)) && !showJobValuationForm &&
                <Button className="is-primary"
                        label={t(isWorker ? 'jobValuation.employerValuation' : 'jobValuation.workerValuation')}
                        onClick={() => setShowJobValuationForm(true)}/>}


                {['started', 'applied', 'finished'].indexOf(job.requestState) > -1 && !job.paid && job.paymentMethod === 'barion' && isEmployer &&
                <ActionButton className="is-primary"
                              action={startPaymentAction}
                              label={t("job.startPayment")}/>}


            </div>}

            <div>
                {showCancelJobForm &&
                <CancelJobForm job={job}
                               loadJob={loadJobAction.run}
                               closeForm={() => setShowCancelJobForm(false)}
                />}

                {showFinishJobForm &&
                <FinishJobForm job={job}
                               loadJob={loadJobAction.run}
                               closeForm={() => setShowFinishJobForm(false)}
                />}
                {showJobValuationForm && isEmployer && <JobValuationByEmployerForm job={job}
                                                                                   loadJob={loadJobAction.run}
                                                                                   closeForm={() => setShowJobValuationForm(false)}/>
                }

                {showJobValuationForm && isWorker && <JobValuationByWorkerForm job={job}
                                                                               loadJob={loadJobAction.run}
                                                                               closeForm={() => setShowJobValuationForm(false)}/>
                }


            </div>

            <ErrorComp error={startPaymentAction.error}/>

            <div className={classNames("container", {"is-blurred": isActionFormActive})}>
                <JobViewComponent job={job}
                                  questions={questions}
                                  yourPrice={yourPrice}
                                  showMessages={true}
                                  disableNewMessages={isActionFormActive}/>
            </div>

            {job.valuatedByEmployer && job.valuatedByWorker && job.jobValuation && <ValuationsSection job={job}/>}


        </div>

    )
}