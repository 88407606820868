import React, {useContext, useState} from 'react';
import {useAction, useLoadAction} from "../hooks";
import * as API from "../api";
import locale_hu from 'dayjs/locale/hu';
import * as classNames from 'classnames'
import {ActionButton, ErrorComp} from "../components/Form";
import {useTranslation} from 'react-i18next';
import {AppNotificationContext} from "../components/AppNotification";


const SEVEN_TO_TWELVE = 'seven_to_twelve';
const TWELVE_TO_FOUR = 'twelve_to_four';
const FOUR_TO_EIGHT = 'four_to_eight';

const periods = [SEVEN_TO_TWELVE, TWELVE_TO_FOUR, FOUR_TO_EIGHT];
const days = ["monday", "tuesday", "wednesday", "thursday", "friday", "saturday", "sunday"];


const periodLabel = (value) => {
    switch (value) {
        case SEVEN_TO_TWELVE:
            return "7 - 12";
        case TWELVE_TO_FOUR:
            return "12 - 16";
        case FOUR_TO_EIGHT:
            return "16 - 21";
    }
};


const convertAvailabilitiesToMap = (availabilities) => {

    const init = days.reduce((res, day) => {
         res[day] = periods.reduce((res, period) => {
             res[period] = false;
             return res;
         }, {});
         return res;
    }, {});


    if (!availabilities) {
        return init;
    }
    return availabilities.reduce((acc, av) => {
        const [day, period] = av.split("-");
        acc[day] = acc[day] || {};
        acc[day][period] = true;
        return acc;
    }, init);

};

const convertAvailabilitiesToList = (availabilitiesMap) => {
    const result = [];

    for (let [day, periods] of Object.entries(availabilitiesMap)) {
        for (let [period, isAvailable] of Object.entries(periods)) {
            if (isAvailable) {
                result.push(day + "-" + period);
            }
        }
    }

    return result;
};

export default function AvailabilityPage() {

    const [t] = useTranslation();
    const notification = useContext(AppNotificationContext);

    const [availability, setAvailability] = useState();

    const loadAvailabilityAction = useLoadAction(async () => {
        const availabilities = await API.availabilities();
        let res = convertAvailabilitiesToMap(availabilities);
        setAvailability(res);
        return res;
    });

    const saveAvailabilityAction = useAction(async () => {

        let availabilitiesList = convertAvailabilitiesToList(availability);


        if (!availabilitiesList || availabilitiesList.length === 0) {
            throw {error: t("availability.validation")}
        }

        try {
            await API.saveAvailabilities(availabilitiesList);
            notification.show(t("availability.saveSucceeded"))
        } catch (e) {
            throw {error: t("availability.saveFailed")}
        }
    });


    if (!loadAvailabilityAction.result) {
        return null;
    }

    console.log("Render",availability["monday"]["four_to_eight"])
    return (
        <div className="availability page">
            <div className="container">
                <div className="buttons">
                    <ActionButton className="is-primary"
                                  action={saveAvailabilityAction}
                                  label={t('save')}/>
                </div>
                <ErrorComp error={saveAvailabilityAction.error}/>

                <table className="table is-bordered">
                    <thead>
                    <tr>
                        <th></th>
                        {[1, 2, 3, 4, 5, 6, 0].map((d, idx) => <th key={idx}>{locale_hu.weekdaysMin[d]}</th>)}
                    </tr>
                    </thead>
                    <tbody>

                    {periods.map(period => <tr key={period}>
                            <td className="">{periodLabel(period)}</td>
                            {days.map(day => <td key={day + "_" + period}
                                                 onClick={() => {
                                                     availability[day][period] = !availability[day][period];
                                                     setAvailability({...availability});
                                                 }}
                                                 className={classNames("availability-period", {selected: availability[day][period]})}></td>)}
                        </tr>
                    )}

                    </tbody>
                </table>
            </div>
        </div>
    )

}