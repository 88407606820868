import React, {useContext, useState} from 'react'
import {Calendar, Day, getEventsForDay} from "../components/Calendar";
import {useTranslation} from 'react-i18next';
import dayjs from "dayjs";
import {NavLink} from "react-router-dom"
import {ReactComponent as Arrow} from '../icons/right-chevron.svg';
import {useLoadAction} from "../hooks";
import {RightArrow} from "../components/Icons";
import * as API from "../api";
import {AppContext} from "../AppRouter";

import News1Image from '../images/news/1.jpg';
import News2Image from '../images/news/3.jpg';
import News3Image from '../images/news/2.jpg';
import EULogo from "../images/szlogo_felso.jpg";

function NewsItem({img, title, content,link}) {
    const [t] = useTranslation();
    return (
        <div className="news-item">
            <div className="news-item-image">
                <img src={img} alt=""/>
            </div>
            <div className="news-item-title title is-5">{title}</div>
            <div className="news-item-content">{content}</div>
            <div className="news-item-button">
                <button className="button is-primary">
                    <NavLink to={link}>
                        {t('home.news.view')}
                    </NavLink>
                </button>
            </div>
        </div>
    )
}


function NewsComponent() {
    const [t] = useTranslation();

    return (
        <div className="news">
            <div className="title is-4 section-title">{t('home.newsTitle')}</div>
            <div className="columns">
                <div className="column">
                    <NewsItem
                        link="/news/1"
                        title="Hogyan rendelnek manapság munkaerőt a neten"
                        img={News1Image}
                        content={(
                            <div>
                                <p>Egyszer régen, egy messzi-messzi galaxisban…</p>
                                <p>Kezdhetnénk így is ezt a témát. Kb. 100 évvel ezelőtt az volt a szokás, hogy egymásnak adták a jó szakemberek nevét az ügyfelek. Szó szerint híre ment, ha valaki korrekt, precíz, pontos szakember volt. Érvényes volt még a mondás is, hogy a “jó bornak nem kell cégér".</p>
                            </div>
                        )}
                    />
                </div>

                <div className="column">
                    <NewsItem
                        link="/news/2"
                        title="Mennyit kereshetsz azzal, hogy online jelentkeznek be hozzád a megrendelők?"
                        img={News2Image}
                        content={(
                            <div>
                                Egy átlagos vállalkozó átlagos reggele azzal indul, hogy végig nézi az e-mailjait, telefon üzeneteit és kiszűri az eleve  haszontalan, spam tartalmakat.
                                Ha valami esetleg fennakad a hálón, akkor jöhet az egyértelműsítés, azaz, hogy mit is kér a megrendelő? Hogy kerülünk vele kapcsolatba? Mikor?
                                Ez sokkal időigényesebb, mint elsőre gondolnánk.
                            </div>
                        )}
                    />
                </div>

                <div className="column">
                    <NewsItem
                        link="/news/3"
                        title="Munka egyetem mellett"
                        img={News3Image}
                        content={(
                            <div>
                                Számos olyan munka van, amihez inkább a hozzáértés szükséges, mint előzetes szakmai tudás.
                            </div>
                        )}
                    />
                </div>

            </div>

        </div>
    )
}

export default function HomePage() {
    const [t] = useTranslation();
    const appContext = useContext(AppContext);

    const [calendarDate, setCalendarDate] = useState(dayjs().startOf('day'));

    const loadPageDataAction = useLoadAction(async () => {
        const notifications = await API.fetchUserNotifications();
        return notifications || [];
    });

    const loadEventsAction = useLoadAction(async () => {
        const events = await API.fetchEvents(calendarDate.year(), calendarDate.month() + 1);
        return events || [];
    })


    if (!loadPageDataAction.result || !loadEventsAction.result) return null;

    const notifications = loadPageDataAction.result.content;
    const events = loadEventsAction.result;
    const isWorker = appContext.user && appContext.user.workerStatus === 'WORKER';

    return (
        <div className="home-page">
            <div className="eu-logo-container">
                <NavLink to="/project">
                    <img src={EULogo} alt="" className="eu-logo-header is-pulled-right"/>
                </NavLink>
            </div>

            <div className="container">
                <div className="columns is-6">
                    <div className="column is-narrow">
                        <Calendar dayRenderer={({day, currentMonth}) => {
                            const eventsOnDay = getEventsForDay(day, events);
                            const dayComp = <Day key={day.unix()}
                                                 day={day}
                                                 currentMonth={currentMonth}
                                                 className={eventsOnDay ? "has-event" : null}/>
                            if (eventsOnDay) {
                                return <NavLink key={day.unix()} to={`/job/${eventsOnDay[0].jobId}`}>{dayComp}</NavLink>
                            } else {
                                return dayComp;
                            }
                        }}/>
                    </div>
                    <div className="column">
                        <div className="notifications">
                            <div className="title is-4 section-title">{t('home.notificationsTitle')}</div>
                            {notifications.length === 0 && <div className="empty-list">{t('emptyList')}</div>}
                            <table className="table is-striped is-narrow is-fullwidth">
                                <tbody>
                                {notifications.map((notification, idx) => {
                                    return (
                                        <tr key={notification.id}>
                                            <td>{dayjs(notification.date).format('YYYY.MM.DD HH:mm')}</td>
                                            <td><strong>{notification.from}</strong></td>
                                            <td>{notification.desc}</td>
                                            <td>
                                                <NavLink to={"/job/" + notification.jobRequestId}>
                                                    <button className="button is-white">
                                                        <span className="button-label is-hidden-mobile">
                                                        {t('home.notifications.view')}
                                                        </span>
                                                        <RightArrow className="svg-icon is-right"/>
                                                    </button>
                                                </NavLink>
                                            </td>
                                        </tr>
                                    )
                                })}

                                </tbody>
                            </table>
                            {isWorker && <div className="is-pulled-right notifications-link">
                                <NavLink to="/jobs" className="is-flex">
                                    {t('home.notifications.showAllJobs')}
                                    <Arrow className="icon"/>
                                </NavLink>
                            </div>}

                        </div>
                    </div>

                </div>
                <NewsComponent/>
            </div>

        </div>

    )
}