import React from 'react';

import News1Image from '../images/news/1.jpg';
import News2Image from '../images/news/3.jpg';
import News3Image from '../images/news/2.jpg';


export function News1Page() {
    return (
        <div className="news-page page">
            <div className="container">
                <div className="news-image">
                    <img src={News1Image} alt=""/>
                </div>

                <div className="content">
                    <h1>Hogyan rendelnek manapság munkaerőt a neten</h1>

                    <p>Egyszer régen, egy messzi-messzi galaxisban…</p>

                    <p>Kezdhetnénk így is ezt a témát. Kb. 100 évvel ezelőtt az volt a szokás, hogy egymásnak adták a jó szakemberek nevét az ügyfelek. Szó szerint híre ment, ha valaki korrekt, precíz, pontos szakember volt. Érvényes volt még a mondás is, hogy a “jó bornak nem kell cégér".</p>

                    <p>Később, ha szakembert kerestek felnyitották az aranyoldalakat és az előminősítés lehetősége nélkül ráböktek a legnagyobb, legszimpatikusabb hirdetésre és vagy jól jártak, vagy nem.</p>
                    <p>Nemrégiben pedig létrejöttek az ajánlói oldalak, illetve chaten pillanatok alatt utána nézhettünk, hogy kit milyen munkavállalónak vélnek, de éreztük, hogy még ez sem az igazi.</p>

                    <p>Manapság pedig már számítógép sem kell hozzá, elővesszük a mobilunkat és egy keresőszó segítségével lassan azt is kideríthetjük, hogy mi a kedvenc vakolókanala a kőművesnek, akit kinéztünk.</p>
                    <p>Még sem ez az igazi megoldás. A népítélet nem mindig korrekt, sokszor egyoldalú.</p>
                    <p>Mi az EliteGuruval arra törekszünk, hogy a Megbízók és a szakemberek sikeresen egymásra találjanak. Ne legyenek vakfoltok. A Megbízó tudja, hogy mit kap a pénzéért a szakember pedig a munkájával tudja tölteni az idejét ne a munkakereséssel.</p>
                </div>

            </div>
        </div>
    )
}

export function News2Page() {
    return (
        <div className="news-page page">
            <div className="container">
                <div className="news-image">
                    <img src={News2Image} alt=""/>
                </div>

                <div className="content">
                    <h1>Mennyit kereshetsz azzal, hogy online jelentkeznek be hozzád a megrendelők?</h1>

                    <p>Lássuk csak!</p>

                    <p>
                        Egy átlagos vállalkozó átlagos reggele azzal indul, hogy végig nézi az e-mailjait, telefon üzeneteit és kiszűri az eleve  haszontalan, spam tartalmakat.<br/>
                        Ha valami esetleg fennakad a hálón, akkor jöhet az egyértelműsítés, azaz, hogy mit is kér a megrendelő? Hogy kerülünk vele kapcsolatba? Mikor?<br/>
                        Ez sokkal időigényesebb, mint elsőre gondolnánk.

                    </p>
                    <p>Általában jó pár e-mail-, telefonváltás után derül ki, hogy partnerek tudunk-e lenni és még csak az ajánlatadás előtt vagyunk.</p>
                    <p>Ezután jön maga az ajánlat, paraméterezés, igények, elvárások tisztázása.
                        Ha ez is megtörtént, akkor jön csak a munkavégzés és már elment több óra, több nap.
                    </p>
                    <p>Az idő pedig pénz, mint tudjuk.</p>
                    <p>Mi lenne, ha megtörne ez a folyamat és többé a munkával tölthetnéd az időd nagy részét, hiszen egy egyszerű szorzással kideríthető, hogy az az idő, amit nem a konkrét munkával töltesz bizony nagyon drága mulatság.</p>
                    <p>Ha pl. 5 ezer forintos órabérrel dolgozol és egyetlen munka, egyetlen ajánlatára elment 2,5 órád (nagyon pozitívak voltunk), akkor elvesztettél 12.500,-Ft-ot és ehhez jön a többi munka. Arról nem is beszélve, hogy nem minden ajánlatból lesz munkavégzés.</p>
                    <p>Persze, mondhatod, hogy a te órabéredben benne van mindez, de tényleg kidobhatsz az ablakon temérdeknyi pénzt?</p>
                    <p>Tényleg nem tudsz mit kezdeni az így megszerzett plusz jövedelemmel.</p>
                    <p>Milyen fantasztikus lehetőség, hogy hozzád már egy előszűrőn keresztül kerülnek a Megbízók! Mindaz a bosszantó előjáték, ami jellemzi a vállalkozók életét, nem a te kezeden fut át, hanem az EliteGuru gondoskodik róla.</p>
                    <p>Ez olyan, mintha lenne egy virtuális asszisztensed</p>
                </div>

            </div>
        </div>
    )
}

export function News3Page() {
    return (
        <div className="news-page page">
            <div className="container">
                <div className="news-image">
                    <img src={News3Image} alt=""/>
                </div>

                <div className="content">
                    <h1>Munka egyetem mellett</h1>

                    <p>Számos olyan munka van, amihez inkább a hozzáértés szükséges, mint előzetes szakmai tudás.</p>
                    <p>Valljuk be őszintén, hogy lehet OKJ-s végzettséget szerezni takarításból (igen, tényleg lehet), de nem szükséges.</p>
                    <p>Elég, ha tisztában vagyunk a képességeinkkel.</p>
                    <p>Ha pedig tudjuk, hogy precízen, pontosan, szépen tudunk takarítani, akkor miért ne hasznosítanánk ezt a tudást.</p>
                    <p>Ráadásul pl. ha jól beszélünk egy nyelvet, lehet, hogy éppen egy angolul jól beszélő takarítót keresnek.</p>
                    <p>Innen pedig már csak egy lépés, hogy egy munkájára igényes diák is elvégezhesse ezt a munkát.</p>
                    <p>Ha te ilyen diák vagy és jó vagy vasalásban, takarításban, bébiszitterkedésben, korrepetálásban, akkor ne fogd vissza magad</p>

                </div>

            </div>
        </div>
    )
}