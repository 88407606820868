export function required(value) {
    if (value === null || value === undefined || value === "") {
        throw {error: 'requiredField'}
    }
}

export function minLength(length) {
    return (value) => {
        required(value);
        if (value.length < length) {
            throw {error: 'minLength', args: {length}}
        }
    }
}

export function emailAddress(value) {
    const re = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    if (!re.test(String(value).toLowerCase())) {
        throw {error: 'invalidEmail'}
    }
}