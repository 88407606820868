import React from 'react';


export function ProjectAboutPage() {
    return (
        <div className="news-page page">
            <div className="container">

                <div className="content">

                    <h1>RÖVID ÖSSZEFOGLALÁS A PROJEKTRŐL</h1>


                    <p><span className="is-underlined">Támogatási szerződés száma:</span>GINOP-2.1.7-15-2016-00447</p>
                    <p><span className="is-underlined">Kedvezményezett:</span> CONSARTIS Kft</p>
                    <p><span className="is-underlined">Projekt megnevezése:</span> Mesterséges intelligenciát alkalmazó prototípus fejlesztése és piacra vitele, on-line közösségi piacokra</p>

                    <p className="is-underlined">A projekt műszaki-szakmai tartalma:</p>

                    <p>
                        Consartis Kft. az alapító-tulajdonosok ötletének felhasználásával egy olyan prototípus
                        fejlesztését kívánja megvalósítani, mely több elemével is nagyban hozzájárul az
                        információtechnológia fejlődéséhez és ezen keresztül a különféle szolgáltatások on-line
                        piacainak kialakításához. Az információtechnológiai kutatás-fejlesztés elsősorban arra
                        irányul, hogy új szolgáltatási szegmensekben alkosson egyedi, még nem alkalmazott
                        megoldásokat a számítástechnika speciális területein, mint a földrajzi vagy más néven
                        geolokációs adatelemzés és felhasználás, valamint mesterséges intelligencia
                        alkalmazása. A tervezett prototípus emellett olyan új ismeretek és megoldások
                        létrehozását és gyakorlati alkalmazását célozza, amely előmozdítja az adatok
                        tárolásának, felhasználásának és visszakereshetőségének általános fejlődését a
                        mindennapi élet és az általános felhasználók szintjén.
                        A létrehozandó prototípussal egy olyan platformot kívánunk biztosítani, mely a
                        keresletet és a kínálatot standardizált módon képes kezelni, a keresletet generáló
                        számára költség, idő és/vagy minőség szempontjából a legjobb megoldást megtalálni, a
                        kínálatot generáló szempontjából pedig költséghatékony módon biztosítja a kínálatának
                        leginkább megfelelő kereslet meglétét, illetve a kereslet-kínálat egymásra találását. A
                        létrehozandó prototípus alkalmas arra, hogy különféle ágazatokban, szegmensekben
                        biztosítsa a fenti célokat a strukturált adatkezelésének és felhasználásának
                        köszönhetően, megtámogatva ezt földrajzi adatok célirányos kezelésével, valamint
                        mesterséges intelligencia felhasználásával, interaktív módon.
                        A prototípus ötletéhez kapcsolódó probléma megfogalmazás, a kutatás-fejlesztés,
                        valamint az üzleti specifikáció elkészítése az alapító-tulajdonosok feladata. A rendszer
                        létrehozatalához kapcsolódó információtechnológiai fejlesztési feladatok elvégzése,
                        valamint tesztelése, testre szabása és a kapcsolódó design kialakítása alvállalkozók
                        bevonásával történik. A fejlesztéseket, paraméterezéseket, fejlesztői teszteket és a pilot
                        üzemet követően a piacra lépés sikeres megvalósítása marketingkampányokkal is
                        megtámogatásra kerül, elsősorban on-line platformok igénybevételével.
                    </p>

                    <p><span className="is-underlined">Támogatott összeg:</span> 47 058 751, - HUF</p>
                    <p><span className="is-underlined">Támogatás aránya:</span> 58%</p>
                    <p><span className="is-underlined">Megvalósítás kezdete:</span> 2017.09.29</p>
                    <p><span className="is-underlined">Megvalósítás vége:</span> 2019.06.30</p>
                </div>

            </div>
        </div>
    )
}