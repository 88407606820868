import React, {useRef, useState} from 'react'
import {useTranslation} from 'react-i18next';
import dayjs from "dayjs";
import {ExpandableList} from "../components/List";
import classNames from 'classnames';
import {usePagingLoadAction} from "../hooks";
import {NavLink} from "react-router-dom"
import {DateField, Form, TextField, useForm} from "../components/Form";
import * as Api from '../api'
import LoadingIndicator from "../components/LoadingIndicator";
import {Check, DownArrow} from "../components/Icons";
import useOnClickOutside from 'use-onclickoutside'


export function StateBox({state}) {
    return <span className={classNames("job-state", "job-state-" + state)}></span>
}

export function addressToString({zipCode, city, street, streetNumber}) {
    return `${zipCode || ""} ${city || ""}, ${street || ""} ${streetNumber || ""}`
}


function AllJobsList(props) {
    return <PagedJobList dataFetcher={(pageOpts) => Api.fetchAllJobs({...pageOpts,limit:100})}/>;
}

function JobAppliesList(props) {
    return <PagedJobList dataFetcher={Api.fetchJobApplies}/>;
}

function FinishedJobsList(props) {
    return <PagedJobList dataFetcher={Api.fetchFinishedJobs}/>;
}

function JobRequestsList(props) {
    return <PagedJobList dataFetcher={Api.fetchJobs}/>;
}


function PagedJobList({dataFetcher}) {
    const loadJobsAction = usePagingLoadAction((pageOptions) => dataFetcher(pageOptions)); //FIXME

    if (loadJobsAction.content.length === 0 && loadJobsAction.isLoading) {
        return <LoadingIndicator/>;
    }

    if (!loadJobsAction.result) return null;
    const jobs = loadJobsAction.content;

    return <JobsList jobs={jobs}
                     loadMore={loadJobsAction.loadMore}
                     loading={loadJobsAction.content.length > 0 && loadJobsAction.isLoading}/>;

}


function JobSorting() {
    const [opened, setOpened] = useState(false);
    const ref = useRef();

    useOnClickOutside(ref, () => setOpened(false));

    return (
        <div ref={ref} className={classNames("dropdown", {"is-active": opened})}>
            <div className="dropdown-trigger">
                <div className="button" onClick={() => setOpened(true)}>
                    <span>Rendezés</span>
                    <DownArrow className="svg-icon is-right"/>
                </div>
            </div>
            <div className="dropdown-menu">
                <div className="dropdown-content">
                    <div className="dropdown-item">
                        Legújabb elől
                    </div>

                    <div className="dropdown-item">
                        <Check className="svg-icon is-left"/>
                        Legújabb hátul
                    </div>

                    <div className="dropdown-item">
                        Távolság szerint csökkenő
                    </div>

                    <div className="dropdown-item">
                        Távolság szerint növekvő
                    </div>

                </div>
            </div>
        </div>
    )
}

export function JobExpandedRow({job}) {
    const {category, jobStartDate, requestUser, shortDescription, address} = job
    const form = useForm({
        category: category.mainCategoryStr + "," + category.label,
        address: addressToString(address),
        jobStartDate, shortDescription,
        requestUser: requestUser.fullName

    });
    const [t] = useTranslation();

    return (
        <Form horizontal={true} form={form}>
            <TextField label={t("job.category")} name="category" readOnly={true}/>
            <TextField label={t("job.address")} name="address" readOnly={true}/>
            <DateField label={t("job.jobStartDate")} name="jobStartDate" readOnly={true}/>
            <TextField label={t("job.requestUser")} name="requestUser" readOnly={true}/>
            <TextField label={t("job.description")} name={shortDescription} readOnly={true}/>
        </Form>
    )
}


export function JobsList({jobs, loadMore, loading}) {
    const [t] = useTranslation();
    const [expandedJob, setExpanded] = useState();

    if (jobs && jobs.length === 0) {
        return <div className="empty-list">{t('emptyList')}</div>
    }
    return (
        <div className="jobs-list list-comp">
            {/*<JobSorting/>*/}
            <ExpandableList rows={jobs}
                            rowRenderer={(job, isExpanded) => {
                                const {id, jobRequestStateLabel, requestState, category, address, description, requestUser, jobStartDate} = job;
                                return (
                                    <>
                                        <td>
                                            <div style={{display: 'flex', alignItems: 'center'}}>
                                                <StateBox state={requestState}/>
                                                <strong>{category.mainCategoryStr + "," + category.label}</strong>
                                            </div>
                                        </td>
                                        <td>{t("requestState."+requestState)}</td>
                                        <td>{(address || {}).city}</td>
                                        <td>{(requestUser || {}).fullName}</td>
                                        <td>{dayjs(jobStartDate).format('YYYY.MM.DD HH:mm')}</td>
                                        <td className="action">
                                            <button className="button is-primary" onClick={(e) => e.stopPropagation()}>
                                                <NavLink to={"/job/" + id}>
                                                    {t('list.open')}
                                                </NavLink>
                                            </button>
                                        </td>
                                    </>
                                )
                            }}
                            expandedRowRenderer={(job) => {
                                return (
                                    <td colSpan="5">
                                        <JobExpandedRow job={job}/>
                                    </td>
                                )
                            }}/>
            <div className="footer-buttons">
                {loadMore && <button className={classNames("button is-primary", {'is-loading': loading})}
                                     onClick={loadMore}>{t('loadMore')}</button>}
            </div>

        </div>
    )
}

export default function JobsPage() {
    const [t] = useTranslation();
    const [activeTab, setActiveTab] = useState('all');

    return (
        <div className="jobs-page">
            <div className="container">
                <div className="tabs">
                    <ul>
                        <li className="page-title title is-4">{t('jobs.title')}</li>
                        <li onClick={() => setActiveTab('all')}><a
                            className={classNames("job-state-all", {"is-active": activeTab === 'all'})}>{t('jobs.allJobs')}</a>
                        </li>
                        <li onClick={() => setActiveTab('started')}><a
                            className={classNames("job-state-started", {"is-active": activeTab === 'started'})}>{t('jobs.jobApplies')}</a>
                        </li>
                        <li onClick={() => setActiveTab('applied')}><a
                            className={classNames("job-state-applied", {"is-active": activeTab === 'applied'})}>{t('jobs.inProgressJobs')}</a>
                        </li>
                        <li onClick={() => setActiveTab('finished')}><a
                            className={classNames("job-state-finished", {"is-active": activeTab === 'finished'})}>{t('jobs.closedJobs')}</a>
                        </li>
                    </ul>
                </div>

                {activeTab === 'applied' && <JobAppliesList/>}
                {activeTab === 'started' && <JobRequestsList/>}
                {activeTab === 'all' && <AllJobsList/>}
                {activeTab === 'finished' && <FinishedJobsList/>}
            </div>


        </div>
    )
}