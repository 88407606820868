import Axios from "axios";

export const googleMapsApiKey = "AIzaSyAy7zGGkUgwE7x4Ngdnk7m7IQi3aaARSBM";
// Add a 401 response interceptor

let history;

export function setHistory(h) {
    history = h;
}


const axios = Axios.create();


axios.interceptors.response.use(function (response) {
    return response;
}, function (error) {
    if (error.response && (401 === error.response.status || 403 === error.response.status)) {
        if (history) {
            history.push("/login");
        }
        return Promise.reject(error)
    } else if (error.response.status >= 500) {
        throw {error: 'server_error'};
    } else {
        return Promise.reject(error);
    }
});

axios.interceptors.response.use(function (response) {
    return response;
}, function (error) {
    if (error.response && (400 === error.response.status)) {
        if (error.response.data.message) {
            return Promise.reject({error: error.response.data.message});
        }
    }
    return Promise.reject(error);
})


export async function fetchStatus() {
    try {
        let resp = await axios.get('/api/status');
        return resp.data;
    } catch (e) {
        if (e.response.status >= 500) {
            throw {error: 'server_error'};

        } else if (e.response.status === 401 || e.response.status === 403) {
            throw {error: 'auth_error'};
        }
    }

}


export async function authenticate(authData) {
    try {
        let user = await Axios.create().post('/api/authenticate', authData);
        return user
    } catch (e) {
        if (e.response.status < 500) {
            throw {error: "login_error"}
        } else {
            throw {error: 'server_error'};
        }

    }
}

export async function logout() {
    await axios.post('/logout');
}


export async function fetchJobs({offset, limit}) {
    const res = await axios.get('/api/jobrequests', {
        params: {
            page: offset || 0, size: limit || 10
        }
    });
    return res.data;
}

export async function myOrderedJobs({offset, limit}) {
    const res = await axios.get('/api/orderedrequests', {
        params: {
            page: offset || 0, size: limit || 10
        }
    });
    return res.data;
}

export async function totalIncome(year) {
    const res = await axios.get('/api/totalIncome/' + year,);
    return res.data;
}


export async function fetchJobApplies({offset, limit}) {
    const res = await axios.get('/api/jobApplies', {
        params: {
            page: offset || 0, size: limit || 10
        }
    });
    return res.data;
}

export async function fetchAllJobs({offset, limit}) {
    const res = await axios.get('/api/allRequest', {
        params: {
            page: offset || 0, size: limit || 10
        }
    });
    return res.data;
}


export async function fetchFinishedJobs({offset, limit}) {
    console.log("Fetching job applies");
    const res = await axios.get('/api/finishedJobApplies', {
        params: {
            page: offset || 0, size: limit || 10
        }
    });
    return res.data;
}


export async function fetchJob({jobId}) {
    return (await axios.get('/api/applyForWorker/' + jobId)).data;
}

export async function acceptJob({jobId}) {
    console.log("Accept job:" + jobId);
    const res = await axios.post('/api/acceptJob/' + jobId);
    return res;
}


export async function saveProfile(profile) {
    const res = await axios.post('/api/profile', profile);
    return res;
}


export async function cancelJob({jobId, cancelReason}) {
    return await axios.post(`/api/job/${jobId}/cancel`, {cancelReason});
}

export async function finishJob({jobId, finalPrice, finalTime, jobValuation}) {
    return await axios.post(`/api/job/${jobId}/finish`, {finalPrice, finalTime, jobValuation});
}

export async function evaluateJob({jobId, valuation}) {
    const res = await axios.post(`/api/job/${jobId}/evaluate`, valuation);
    return res;
}

export async function fetchMessagesForJobApply({jobId}) {
    return (await axios.get('/api/jobRequestComments/' + jobId)).data;
}

export async function sendMessageToJob({jobId, message}) {
    const res = await axios.post('/api/addComment/' + jobId, {message});
    return res.data;
}

export async function priceForRequest(request) {
    const res = await axios.post('/api/priceForRequest', request);
    return res.data;
}

export async function fetchProfile() {
    const res = await axios.get('/api/profile');
    return res.data;
}


export async function fetchCategoryQuestions({categoryId}) {
    return (await axios.get("/api/category-questions", {params: {categoryId}})).data;
}

export async function fetchCategories() {
    return (await axios.get("/api/categories")).data;
}


export async function requestProposal(jobRequest) {
    return (await axios.post("/api/saveRequest", jobRequest)).data;
}


export async function fetchLatestComments() {
    return await axios.get("/api/latestComments");
}

export async function availabilities() {
    return (await axios.get("/api/availabilities")).data;
}

export async function saveAvailabilities(availabilities) {
    return await axios.post("/api/availabilities", availabilities);
}

export async function startPayment(jobId) {
    let response = (await axios.post("/api/payment/start/" + jobId)).data;
    return response;
}

export async function signUp(user) {
    return await axios.post("/api/signup", user);
}

export async function myCategories() {
    return (await axios.get("/api/my-categories")).data;
}


export async function saveCategory(category) {
    return (await axios.post("/api/my-categories", category));
}

export async function fetchUserNotifications() {
    return (await axios.get("/api/userNotifications",{
        params: {
            page: 0, size: 10
        }
    })).data;
}

export async function lostPassword(email) {
    return (await axios.post(`/api/lostpassword`, {email})).data;
}

export async function changePassword(params) {
    return (await axios.post(`/api/changePassword`, params)).data;
}

export async function fetchEvents(year, month) {
    return (await axios.get(`api/events/${year}/${month}`)).data;
}






