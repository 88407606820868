import React, {useContext, useEffect, useState} from 'react';
import {useTranslation} from 'react-i18next';
import {
    ActionButton,
    DateField,
    ErrorComp,
    Form,
    PasswordField,
    PhoneNumberField,
    SelectField,
    TextField,
    useForm
} from "../components/Form";
import dayjs from "dayjs";
import {useAction} from "../hooks";
import * as API from "../api";
import {GoogleLoginButton} from "./LoginPage";
import {Envelope} from "../components/Icons";
import Modal from "../components/Modal";
import {AppContext} from "../AppRouter";
import {Redirect} from "react-router-dom"


const ModalWithRef = React.forwardRef((props, ref) => {
    return <Modal ref={ref} {...props}/>
});

export const SignUpMethodSelector = React.forwardRef((props, ref) => {

    const [formData,setFormData] = useState();
    const [t] = useTranslation();


    if (formData) {
        return <Redirect to={{pathname:"/signup",state:{formData}}} />
    }

    return (
        <ModalWithRef ref={ref} close={() => {
        }}>
            <div className="signup-method-selector login-page">
                <div className="buttons">
                    <GoogleLoginButton
                        label={t("login.continueWithGoogle")}
                        onSuccess={({profileObj}) => {
                            const formData = {
                                socialSignUp:true,
                                email: profileObj.email,
                                firstName: profileObj.givenName,
                                lastName: profileObj.familyName,
                            };
                            setFormData(formData);
                        }}
                        onFailure={console.log}
                    />

                    <div className="stroked">
                        Or
                    </div>

                    <button className="button" onClick={() => setFormData({})}>
                        <Envelope className="svg-icon is-left"/>
                        {t('login.continueWithEmail')}
                    </button>

                </div>

            </div>
        </ModalWithRef>

    )

});

function SignUpForm(props) {
    console.log("Form:",props);
    const [t] = useTranslation();
    const {socialSignUp,...formData} = props.formData;
    const form = useForm(formData || {});
    const signupAction = useAction(async () => {
        if (await form.validate()) {
            const {passwordConfirm, ...user} = form.values
            await API.signUp(user);
            return true;
        }
    });


    const emailField = {
        name: "email",
        label: t("profile.email"),
        required: true
    };


    const firstNameField = {
        name: "firstName",
        label: t("profile.firstName"),
        required: true
    };
    const lastNameField = {
        name: "lastName",
        label: t("profile.lastName"),
        required: true
    };
    const birthDateField = {
        name: "birthDate",
        label: t("profile.birthDate"),
        required: true
    };
    const genderField = {name: "gender", label: t("profile.gender"), required: true};

    const phoneNumberField = {
        name: "phoneNumber",
        label: t("profile.phoneNumber"),
        required: true
    };


    const passwordField = {name: "password", label: t("profile.password")};
    const passwordFieldConfirm = {name: "passwordConfirm", label: t("profile.passwordConfirm")};

    const [passwordError, setPasswordError] = useState();



    function confirmPasswordValidator(pass) {
        if (pass != form.values.password) {
            setPasswordError("signup.passwordDoesntMatch")
        } else {
            setPasswordError(null);
        }
    }

    useEffect(() => {
        if (form.values.password && form.values.passwordConfirm !== undefined && form.values.passwordConfirm != form.values.password) {
            setPasswordError("signup.passwordDoesntMatch")
        } else {
            setPasswordError(null);
        }
    }, [form.values.passwordConfirm, form.values.password])

    return (
        <div className="signup-page">
            <div className="container">
                <Form form={form}>
                    <TextField {...emailField} autoFocus={true} readOnly={socialSignUp}/>
                    <TextField {...lastNameField}/>
                    <TextField {...firstNameField}/>

                    <DateField {...birthDateField}
                               defaultDate={dayjs("1980-01-01")}
                               minDate={dayjs("1900-01-01")}
                               maxDate={dayjs().subtract(18, "year")}
                               defaultView={"years"}/>
                    <SelectField {...genderField} options={[
                        {value: "MALE", label: t("gender.male")},
                        {value: "FEMALE", label: t("gender.female")}]}/>
                    <PhoneNumberField {...phoneNumberField}/>

                    <PasswordField {...passwordField} required={!socialSignUp}/>
                    <PasswordField {...passwordFieldConfirm} required={!socialSignUp}/>

                    <ErrorComp error={{error: passwordError}}/>
                </Form>

                <ActionButton action={signupAction} label={t('signup.signup')} className="is-primary"/>
                <ErrorComp error={signupAction.error}/>
                {signupAction.result && <Redirect to={{pathname:"/login",state:"successfulRegistration"}}/>}

                {/*<div>{JSON.stringify(signupAction)}</div>*/}
            </div>
        </div>
    );

}


export default function SignUpPage(props) {
    const {routerProps} = useContext(AppContext);
    return <SignUpForm formData={routerProps.location.state.formData || {}}/>;
}