import React, {useState} from 'react';
import {ActionButton, Button, ErrorComp, Form, NumberField, SelectField, TextField, useForm} from "../components/Form";
import {useTranslation} from 'react-i18next';
import {useAction, useLoadAction} from "../hooks";
import * as API from '../api';
import Modal from "../components/Modal";
import NumberFormat from "react-number-format";
import {range} from "../utils";


function MyCategoriesComponent({category, categories,myCategories, close}) {
    const [t] = useTranslation();

    const form = useForm(category);

    const saveCategoryAction = useAction(async () => {
        if (await form.validate()) {
            await API.saveCategory(form.values);
            close(true);
            return true;
        }

    })

    const pricePerHourField = {
        name: "pricePerHour",
        label: t("myCategories.pricePerHour"),
        required: true
    };

    const categoryField = {
        name: "category",
        label: t("myCategories.category"),
        required: true
    };


    const experienceField = {
        name: "experience",
        label: t("myCategories.experience"),
        required: true,
    };

    const experienceOptions = range(0, 20).map(hour => ({value: hour, label: hour + " " + t('year'), required: true}));

    return (
        <div className="my-categories">
            <div className="box">
                <Form form={form}>
                    {!form.values.id && <SelectField {...categoryField}
                                                     maxMenuHeight={200}
                                                     options={categories.map(mainCat => {
                                                         return {
                                                             label: t("jobMainCategory." + mainCat.mainCategory),
                                                             options: mainCat.categories.filter(c => form.values.id || myCategories.indexOf(c) == -1).map(cat => ({
                                                                 label: t("jobcategory." + cat),
                                                                 value: cat
                                                             }))
                                                         }
                                                     })}/>
                    }



                    {form.values.id && <TextField {...categoryField} value={t("jobMainCategory." + form.values.mainCategory) + " - " + t("jobcategory." + form.values.category)} readOnly={true}/>}
                    <NumberField {...pricePerHourField} suffix={" Ft"}/>

                    <SelectField menuPlacement={"top"} maxMenuHeight={150} {...experienceField} options={experienceOptions} />

                </Form>

                <ErrorComp error={saveCategoryAction.error}/>
                <div className="buttons top-margin">
                    <ActionButton action={saveCategoryAction} className="is-primary" label={t("save")}/>
                    <Button label={t("cancel")} onClick={() => close(false)}/>
                </div>
            </div>
        </div>
    )
}


function MyCategoriesList() {
    const [t] = useTranslation();

    const loadMyCategoriesAction = useLoadAction(() => API.myCategories());
    const loadCategoriesAction = useLoadAction(() => API.fetchCategories());

    const [editedCategory, setEditedCategory] = useState();

    if (loadMyCategoriesAction.isLoading) {
        return null;
    }

    const categories = loadCategoriesAction.result;


    function addNewCategory() {
        setEditedCategory({});
    }

    function closeModal(refresh) {
        setEditedCategory(null);
        if (refresh) {
            loadMyCategoriesAction.run();
        }
    }


    const myCategories = loadMyCategoriesAction.result;

    return (
        <div className="my-categories-list">

            {editedCategory && categories && (
                <Modal className="my-categories" opened={true} onClose={closeModal}>
                    <MyCategoriesComponent category={editedCategory}
                                           categories={categories}
                                           myCategories={myCategories.map(uwc => uwc.category)}
                                           close={closeModal}/>
                </Modal>)}

            <div className="container">

                <div className="buttons">
                    <Button label={t("myCategories.add")} className="is-primary" onClick={addNewCategory}/>
                </div>

                <table className="table is-fullwidth is-narrow">
                    <thead>
                    <tr>
                        <th>{t('myCategories.mainCategory')}</th>
                        <th>{t('myCategories.category')}</th>
                        <th>{t('myCategories.pricePerHour')}</th>
                        <th>{t('myCategories.state')}</th>
                        <th></th>
                    </tr>
                    </thead>
                    <tbody>
                    {myCategories.map(row => (
                        <tr key={row.id}>
                            <td>{t("jobMainCategory." + row.mainCategory)}</td>
                            <td>{t("jobcategory." + row.category)}</td>
                            <td><NumberFormat displayType="text" value={row.pricePerHour} thousandSeparator=" "
                                              suffix=" Ft"/></td>
                            <td>{t(`myCategories.statusLabel.${row.state}`)}</td>
                            <td><Button onClick={() => setEditedCategory(row)} className="is-primary"
                                        label={t('modify')}/></td>
                        </tr>
                    ))}
                    </tbody>
                </table>
            </div>
        </div>
    )
}

export default function MyCategoriesPage() {
    return <MyCategoriesList/>
}