import React, {useEffect,useState} from 'react';
import {Loading} from "./Icons";
import classNames from 'classnames'

/**
 *
 * @param props {{delay}}
 * @returns {*}
 * @constructor
 */
export default function LoadingIndicator(props) {
    const {delay = 200} = props;
    const [show, setShow] = useState(delay ? false : true);
    useEffect(() => {
        let timeout;
        if (delay) {
            timeout = setTimeout(() => setShow(true), delay)
        }
        return () => {
            if (timeout) {
                clearTimeout(timeout);
            }
        }
    }, [])

    return (
        <div className={classNames("loading-indicator", props.className)}>
            {show && <Loading/>}
        </div>
    )
}