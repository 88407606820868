import React, {useContext, useEffect, useRef, useState} from 'react';
import LoadingIndicator from "./LoadingIndicator";
import {googleMapsApiKey} from "../api";
import {FormContext, useField, wrapInput} from "./Form";
import {useTranslation} from 'react-i18next';
import * as Validators from './validators';
import GoogleMapReact from 'google-map-react';

export function placeToString(place) {
    if (!place) return "";
    return addressToString(placeToAddress(place))
}

export function addressToString(address) {
    if (!address) return "";
    const {zipCode, city, street, streetNumber} = address || {};
    return [zipCode, city, street, streetNumber].filter(part => !!part).join(' ');

}


function placeToAddress(place) {
    if (!place || !place.address_components) return {};

    const addressTypes = ['postal_code', 'locality', 'route', 'street_number'];

    const address = addressTypes.reduce((res, type) => {
        const addressPart = place.address_components.find(ac => ac.types.indexOf(type) > -1);
        if (addressPart) {
            switch (type) {
                case 'postal_code':
                    res.zipCode = addressPart.short_name;
                    break;
                case 'locality':
                    res.city = addressPart.short_name;
                    break;
                case 'route':
                    res.street = addressPart.short_name;
                    break;
                case 'street_number':
                    res.streetNumber = addressPart.short_name;
                    break;

            }
        }
        return res;
    }, {});

    return address;

}


function geoCode(maps, latlng) {
    return new Promise((resolve, reject) => {
        if (!latlng || !maps) {
            reject();
        }

        new maps.Geocoder().geocode({'location': latlng}, function (results, status) {
            if (status === maps.GeocoderStatus.OK) {
                var place = results[0];
                resolve(place)
            } else {
                reject('Geocoder failed due to: ' + status);
            }
        });
    })

}


export function SearchBox(props) {
    const {placeholder, onBlur, fieldProps} = props;
    const [t] = useTranslation();

    const mapApi = props.mapsApi ? props.mapsApi.maps : undefined;
    const inputRef = useRef();
    const searchBox = useRef();
    const placeRef = useRef();
    const [addressString, setAddressString] = useState(() => {
        if (props.place) {
            return addressToString(props.place);
        }
        return null;
    });

    const onPlaceChanged = ({mapsApi, setPlace} = props) => {
        const map = mapsApi.map;
        const place = searchBox.current.getPlace();
        if (!place.geometry) return;
        if (place.geometry.viewport) {
            map.fitBounds(place.geometry.viewport);
        } else {
            map.setCenter(place.geometry.location);
            map.setZoom(17);
        }

        //console.log("Place:", place);
        inputRef.current.value = placeToString(place);

        placeRef.current = placeToAddress(place);
        setPlace(place);
    };


    useEffect(() => {
        if (mapApi) {
            //geoCode(mapApi, props.place ? props.place.location : null).then(placeToString).then(setAddressString);
            searchBox.current = new mapApi.places.Autocomplete(inputRef.current);
            searchBox.current.addListener('place_changed', onPlaceChanged);
            searchBox.current.bindTo('bounds', props.mapsApi.map);
            return () => mapApi.event.clearInstanceListeners(inputRef.current);
        }
    }, [mapApi]);

    if (!props.mapsApi) {
        return <LoadingIndicator/>;
    }


    return (
        <div>
            <input
                ref={inputRef}
                type="text"
                className="input"
                defaultValue={addressString}
                placeholder={placeholder}
                onBlur={e => {
                    if (props.required) {
                        try {
                            Validators.required(e.target.value);
                            fieldProps.validator(placeRef.current);
                            fieldProps.setError(null);
                        } catch ({error, args}) {
                            const errorMsg = t(error, args);
                            fieldProps.setError(errorMsg);
                            return false;
                        }
                    }
                }}
            />
        </div>
    )
}

export const Budapest = {lat: 47.497912, lng: 19.04023499999994};


export function getGeoLocation() {
    return new Promise((resolve, reject) => {
        if (navigator.geolocation) {
            navigator.geolocation.getCurrentPosition(
                position => {
                    resolve({
                        lat: position.coords.latitude,
                        lng: position.coords.longitude
                    })
                }
            )
        } else {
            return Budapest;
        }

    })

}


function createMarkerAndCircle({map, maps}, location, radius) {

    const marker = new maps.Marker({
        map: map,
        position: location,
        anchorPoint: new maps.Point(0, 0)
    });

    let circle;
    if (radius) {
        circle = new maps.Circle({
            map: map,
            radius: radius,
            fillColor: '#AA0000',
            fillOpacity: 0.35,
            strokeColor: '#AA0000',
            strokeOpacity: 0.8,
            strokeWeight: 2
        });

        circle.bindTo('center', marker, 'position');

        // Fit map to bounds
        const bounds = circle.getBounds();
        if (bounds) {
            map.fitBounds(bounds);
        }
    }

    return {circle, marker};
}


export function MapComponent(props) {
    const [mapState, setMapState] = useState();
    const [circleAndMarker, setCircleAndMarker] = useState();

    useEffect(() => {
        if (mapState) {
            if (circleAndMarker) {
                if (circleAndMarker.circle) {
                    circleAndMarker.circle.setMap(null);
                }
                circleAndMarker.marker.setMap(null);
            }
            setCircleAndMarker(createMarkerAndCircle(mapState, props.center, props.radius));
        }
    }, [props.center, props.radius, mapState]);


    return (
        <div className="google-maps" style={props.style || {height: '250px', width: '250px'}}>
            <GoogleMapReact
                bootstrapURLKeys={{
                    key: googleMapsApiKey,
                    libraries: ['places', 'geometry']
                }}
                center={props.center || Budapest}
                defaultZoom={props.zoom || 7}
                distanceToMouse={(pt, mousePos /* , markerProps */) => {
                    if (!pt) return 0;
                    return Math.sqrt((pt.x - mousePos.x) * (pt.x - mousePos.x) + (pt.y - mousePos.y) * (pt.y - mousePos.y));
                }}
                yesIWantToUseGoogleMapApiInternals={true}
                onGoogleApiLoaded={({map, maps}) => {
                    if (props.onMapsApi) {
                        props.onMapsApi({maps, map});
                    }
                    setMapState({map, maps});
                }}
            >
            </GoogleMapReact>
        </div>
    )
}

export function AddressField(props) {
    const fieldProps = useField(props);
    const form = useContext(FormContext);
    const {ref, value, error, onChange, onBlur, validate, setValue} = fieldProps;

    const {radius, zoom, placeholder, mapStyle} = props;
    const [mapsApi, setMapsApi] = useState();
    const [t] = useTranslation();
    const formRef = useRef();

    useEffect(() =>  {
        formRef.current = form;
    },[form])

    const setPlace = (place) => {
        let addr;
        if (!place || !place.geometry) {
            addr = null
        } else {
            addr = {
                ...placeToAddress(place),
                location: {
                    lat: place.geometry.location.lat(),
                    lng: place.geometry.location.lng()
                }

            };
        }

        formRef.current.setValue(props.name, addr);
        formRef.current.validateField(props.name);
    };

    //console.log("Address props:", props, form.values);

    return wrapInput({...props, error}, (
        <div className="address-field">
            <SearchBox
                mapsApi={mapsApi}
                placeholder={placeholder}
                fieldProps={fieldProps}
                required={props.required}
                place={value}
                onBlur={onBlur}
                setPlace={setPlace}/>
                <div className="help">
                    {t('job.chooseAddress')}
                </div>
            <MapComponent
                center={value ? value.location : undefined}
                zoom={zoom}
                style={mapStyle}
                onMapsApi={setMapsApi}
                radius={radius}/>
        </div>));
}
