import React, {createContext, lazy, useContext, useEffect, useState} from "react";
import {BrowserRouter as Router, Redirect, Route, Switch, withRouter} from "react-router-dom";
import HomePage from "./pages/HomePage";
import Menu from "./components/Menu";
import JobsPage from "./pages/JobsPage";
import MessagesPage from "./pages/MessagesPage";
import JobDetailsPage from "./pages/JobDetailsPage";
import LoginPage from "./pages/LoginPage";
import * as API from "./api";
import CreateJobPage from "./pages/CreateJobPage";
import MyJobRequestsPage from "./pages/MyJobRequestsPage";
import PublicHomePage from "./pages/public/PublicHomePage";
import AboutUsPage from "./pages/public/AboutUsPage";
import AppNotification, {AppNotificationProvider} from "./components/AppNotification";
import AvailabilityPage from "./pages/AvailabilityPage";
import SignUpPage from "./pages/SignUpPage";
import MyCategoriesPage from "./pages/MyCategoriesPage";
import ChangePasswordPage from "./pages/ChangePasswordPage";
import WhyPage from "./pages/public/WhyPage";
import Footer from "./components/Footer";
import {Cookies, FAQ, PrivacyPolicy, TermsAndConditions} from "./pages/public/StaticPages";
import FinancePage from "./pages/FinancePage";
import {News1Page, News2Page, News3Page} from "./pages/NewsPages";
import {ProjectAboutPage} from "./pages/ProjectAboutPage";

const ProfilePage = lazy(() => import("./pages/ProfilePage"));

export const AppContext = createContext({});

const ANONYMOUS_USER = {anonymous: true};

class _ScrollToTop extends React.Component {

    componentDidUpdate(prevProps) {
        if (this.props.location.pathname !== prevProps.location.pathname) {
            window.scrollTo(0, 0);
        }
    }

    render() {
        return this.props.children;
    }
}


const ScrollToTop = withRouter(_ScrollToTop);
function PrivateRoute({component: Component, ...rest}) {
    const appContext = useContext(AppContext);

    return <Route {...rest} render={(props) => {
        return appContext.user == ANONYMOUS_USER ? <Redirect to={{
            pathname: '/login',
            state: {from: props.location}
        }}/> : <Component {...props}/>
    }}/>
}

function AuthenticatedComp(props) {
    const appContext = useContext(AppContext);
    return (
        <div className="root">
            <Menu/>
            <AppNotification/>
            <div className="app-content">
                <Switch>
                    <Route path="/login" exact component={LoginPage}/>
                    <PrivateRoute path="/profile" exact component={() => <ProfilePage/>}/>
                    <PrivateRoute path="/jobs" exact component={JobsPage}/>
                    <PrivateRoute path="/job/:id" component={JobDetailsPage}/>
                    <PrivateRoute path="/messages" exact component={MessagesPage}/>
                    <PrivateRoute path="/my-job-requests" exact component={MyJobRequestsPage}/>
                    <PrivateRoute path="/availability" exact component={AvailabilityPage}/>
                    <PrivateRoute path="/categories" exact component={MyCategoriesPage}/>
                    <PrivateRoute path="/finance" exact component={FinancePage}/>
                    <PrivateRoute path="/news/1" exact component={News1Page}/>
                    <PrivateRoute path="/news/2" exact component={News2Page}/>
                    <PrivateRoute path="/news/3" exact component={News3Page}/>

                    <Route path="/aboutUs" exact component={AboutUsPage}/>
                    <Route path="/login" exact component={LoginPage}/>
                    <Route path="/signup" exact component={SignUpPage}/>
                    <Route path="/bookNow" component={PublicHomePage}/>
                    <Route path="/privacypolicy" component={PrivacyPolicy}/>
                    <Route path="/termsAndConditions" component={TermsAndConditions}/>
                    <Route path="/faq" component={FAQ}/>
                    <Route path="/cookies" component={Cookies}/>
                    <Route path="/create-job" component={CreateJobPage}/>
                    <Route path="/changePassword/:token" component={ChangePasswordPage}/>
                    <Route path="/why" component={WhyPage}/>
                    <Route path="/home" exact component={PublicHomePage}/>
                    <Route path="/project" exact component={ProjectAboutPage}/>

                    {!appContext.isLoading && appContext.isAuthenticated &&
                    <PrivateRoute path="/" exact component={HomePage}/>}
                    {!appContext.isLoading && !appContext.isAuthenticated &&
                    <Route path="/" exact component={PublicHomePage}/>}
                </Switch>
            </div>
            <Footer/>
        </div>
    )

}

const defaultStatus = {user: ANONYMOUS_USER};
export default function AppRouter() {

    const [appContext, setAppContext] = useState({});
    const [status, setStatus] = useState(null);
    const [isLoadingStatus, setIsLoadingStatus] = useState(true);
    const [showMenu,setShowMenu] = useState(true);
    const [counter, setCounter] = useState(0);


    const fetchStatus = async () => {
        setIsLoadingStatus(true);
        return API.fetchStatus()
            .then(status => {
                setStatus(status);
                return status;
            })
            .catch(() => {
                setStatus(defaultStatus);
                return defaultStatus;
            })
            .then((stat) => {
                setStatus(stat);
                setIsLoadingStatus(false);
            });
    }

    const logout = () => setStatus(defaultStatus);

    useEffect(() => {
        fetchStatus();
    }, [])

    if (!status) return null;

    return (
        <React.Fragment>
            <Router>
                <ScrollToTop>
                    <Route render={(props) => {
                        API.setHistory(props.history);
                        return (
                            <AppContext.Provider value={{
                                ...appContext,
                                user: (status || defaultStatus).user,
                                routerProps: props,
                                isAuthenticated: (status || defaultStatus).user !== ANONYMOUS_USER,
                                isLoading: isLoadingStatus,
                                showMenu,setShowMenu,
                                counter, setCounter,
                                fetchStatus, logout,
                                setAppContext
                            }}>
                                <AppNotificationProvider>
                                    <AuthenticatedComp/>
                                </AppNotificationProvider>

                            </AppContext.Provider>);
                    }}/>
                </ScrollToTop>
            </Router>
        </React.Fragment>

    )
};