import React, {useContext, useEffect, useRef, useState} from 'react'
import {AppNotificationContext} from "./components/AppNotification";
import {useTranslation} from 'react-i18next';

export function useAction(f, initialState) {

    const notifications = useContext(AppNotificationContext);
    const [t] = useTranslation();

    const [state, setState] = useState(initialState || {
        result: null,
        isLoading: false,
        error: null
    });
    const isMounted = useRef();


    function setError(error) {
        setState({...state, error: null})
    }

    useEffect(() => {
        isMounted.current = true;
        return () => {
            isMounted.current = false;
        }
    }, [])

    const run = async () => {
        setState(state => ({...state, isLoading: true, error: null}));
        notifications.clear();
        try {
            const result = await Promise.resolve(f());
            isMounted.current && setState({isLoading: false, result, error: null});
        } catch (e) {
            isMounted.current && setState({isLoading: false, result: null, error: e});
            if (e.error === "server_error") {
                notifications.show({message:t(e.error),type:"error"});
            }
        }
    }

    return {...state, setState, setError, run};

}

export function useLoadAction(f, initialResult) {
    const loadAction = useAction(f, {
        result: null,
        isLoading: true,
        error: null
    });

    useEffect(() => {
        if (!initialResult) {
            loadAction.run();
        } else {
            loadAction.setState({isLoading: false, result: initialResult, error: null});
        }

    }, []);

    return loadAction;
}

const PAGE_SIZE = 20;

export function usePagingLoadAction(f) {
    const [pageOptions, setPageOptions] = useState({offset: 0, limit: PAGE_SIZE});
    const [content, setContent] = useState([]);
    const action = useLoadAction(() => f(pageOptions),[])

    useEffect(() => {

        if (action.result && action.result.content) {
            setContent([...content, ...action.result.content]);
        }
    }, [action.result])

    useEffect(() => {
        action.run();
    }, [pageOptions])


    function loadMore() {
        if (!action.last) {
            setPageOptions({...pageOptions, offset: pageOptions.offset + 1})
        }
    }

    return {...action,content, loadMore:action.result && action.result.last ? null : loadMore}
}

export function usePrevious(value) {
    const ref = useRef(value);
    useEffect(() => {
        ref.current = value;
    });
    return ref.current;
}


export function useHover() {
    const [value, setValue] = useState(false);

    const ref = useRef(null);

    const handleMouseOver = () => setValue(true);
    const handleMouseOut = () => setValue(false);

    useEffect(
        () => {
            const node = ref.current;
            if (node) {
                node.addEventListener('mouseover', handleMouseOver);
                node.addEventListener('mouseout', handleMouseOut);

                return () => {
                    node.removeEventListener('mouseover', handleMouseOver);
                    node.removeEventListener('mouseout', handleMouseOut);
                };
            }
        },
        [ref.current] // Recall only if ref changes
    );

    return [ref, value, setValue];
}