import React from 'react'
import {useTranslation} from 'react-i18next';
import {usePagingLoadAction} from "../hooks";
import * as Api from '../api'
import {JobsList} from "./JobsPage";
import {NavLink} from "react-router-dom";
import LoadingIndicator from "../components/LoadingIndicator";


export default function MyJobRequestsPage() {
    const [t] = useTranslation();
    const loadJobsAction = usePagingLoadAction((pageOptions) => Api.myOrderedJobs(pageOptions)); //FIXME

    if ( loadJobsAction.content.length === 0 && loadJobsAction.isLoading) {
        return <LoadingIndicator />;
    }

    if (!loadJobsAction.result) return null;
    const jobs = loadJobsAction.content;

    return (
        <div className="my-job-requests page">
            <div className="container">
                <div className="buttons">
                    <NavLink to="/bookNow">
                        <button className="button is-primary">{t("createJobRequest")}</button>
                    </NavLink>
                </div>
                <JobsList jobs={jobs} loadMore={loadJobsAction.loadMore} loading={loadJobsAction.content.length > 0 && loadJobsAction.isLoading}/>
            </div>
        </div>
    )
}