import React, {useState} from 'react';

export function ExpandableList({rowRenderer, expandedRowRenderer, rows = []}) {
    const [expandedRow, setExpanded] = useState();

    return (
        <table className="table is-fullwidth expandable-list is-narrow">
            <tbody>
            {rows.map(row => {
                const renderedRow = (
                    <tr key={row.id} onClick={() => setExpanded(row !== expandedRow ? row : null)}>
                        {rowRenderer(row,expandedRow === row)}
                    </tr>)

                if (expandedRow === row) {
                    return (
                        <React.Fragment key={row.id}>
                            {renderedRow}
                            <tr className="expanded">
                                {expandedRowRenderer(row)}
                            </tr>
                        </React.Fragment>
                    )
                }
                return renderedRow;
            })}
            </tbody>
        </table>
    )
}
