import React from 'react';
import {NavLink} from "react-router-dom";
import {Facebook, Instagram} from "./Icons";
import EULogo from '../images/szlogo_also.jpg';


export default function Footer() {
    return (
        <footer>
            <NavLink to="/project">
                <img src={EULogo} alt="" className="eu-logo"/>
            </NavLink>

            <div className="container">
                <div className="columns">
                    <div className="column">
                        <div>Copyright @ Elitguru 2019</div>
                    </div>

                    <div className="column">
                        <div>
                            <NavLink to="/privacypolicy">
                                Adatvédelmi Tájékoztató
                            </NavLink>
                        </div>
                        <div>
                            <NavLink to="/termsAndConditions">
                                Általános Szerződési Feltételek
                            </NavLink>
                        </div>

                    </div>

                    <div className="column">
                        <div>Kapcsolat: <a href="mailto:info@eliteguru.hu">info@eliteguru.hu</a></div>
                        <div>Ügyfélszolgálat: +36-20-360-8929</div>
                    </div>

                    <div className="column">
                        <div className="follow-us">
                            <span>Kövess minket:</span>
                            <span>
                            <a href="https://www.facebook.com/Elite-Guru-245378586337537/">
                                <Facebook className="icon social-icon"/>
                            </a>

                            <a href="https://www.instagram.com/elitguru/">
                                <Instagram className="icon social-icon"/>
                            </a>
                        </span>
                        </div>

                        <NavLink to="/faq">GYIK</NavLink>
                    </div>

                </div>

                {/*<div className="icons-license">
                    <a href="https://fontawesome.com/license">Fontawesome icons</a>
                </div>*/}


            </div>

        </footer>
    )
}