import React, {useEffect, useState} from 'react';
import {useTranslation} from 'react-i18next';
import {ActionButton, ErrorComp, Form, PasswordField, useForm} from "../components/Form";
import {useAction} from "../hooks";
import Modal from "../components/Modal";
import {Redirect} from "react-router-dom";
import * as API from "../api";


export default function ChangePasswordPage(props) {
    const [t] = useTranslation();
    const form = useForm({});

    const [passwordError, setPasswordError] = useState();

    const changePasswordAction = useAction(async () => {
        if (await form.validate()) {

            await API.changePassword({
                token: props.match.params.token,
                password: form.values.password,
                passwordConfirm:form.values.passwordConfirm
            });

            return true;
        }
    });

    const passwordField = {name: "password", label: t("profile.password")};
    const passwordFieldConfirm = {name: "passwordConfirm", label: t("profile.passwordConfirm")};

    const [redirectToLogin,setRedirectToLogin] = useState(false);
    useEffect(() => {
        if (form.values.password && form.values.passwordConfirm !== undefined && form.values.passwordConfirm != form.values.password) {
            setPasswordError("signup.passwordDoesntMatch")
        } else {
            setPasswordError(null);
        }
    }, [form.values.passwordConfirm, form.values.password])

    if (redirectToLogin) {
        return <Redirect to="/login"/>
    }

    if (!passwordError && changePasswordAction.result) {
        return <Modal opened={true} onClose={() =>setRedirectToLogin(true)}>
            <div className="box">
                {t("changePassword.successful")}
            </div>

        </Modal>
    }
    return (
        <div className="change-password-page login-page page">
            <div className="content">
                <h2>{t("changePassword.giveNewPassword")}</h2>
                <Form form={form}>
                    <PasswordField {...passwordField} required={true}/>
                    <PasswordField {...passwordFieldConfirm} required={true}/>

                    <div className="buttons">
                        <ActionButton className="is-primary" action={changePasswordAction}
                                      label={t("changePassword.changePassword")}/>
                    </div>

                    <ErrorComp error={changePasswordAction.error || {error: passwordError}}/>

                </Form>
            </div>
        </div>

    );
}