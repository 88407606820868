import React, {useContext, useEffect, useMemo, useState} from 'react';
import {Section} from "../components/other";
import {
    ActionButton,
    DateField,
    ErrorComp,
    Form,
    FormContext,
    NumberField,
    PasswordField,
    PhoneNumberField,
    SelectField,
    TextAreaField,
    TextField,
    useField,
    useForm,
    wrapInput
} from "../components/Form";
import {useAction, useLoadAction} from "../hooks";
import * as API from "../api";
import {useTranslation} from 'react-i18next';
import dayjs from "dayjs";
import {range} from "../utils";
import {AddressField} from "../components/maps";
import {AppContext} from "../AppRouter";
import {NavLink, Redirect} from "react-router-dom";
import {Check} from "../components/Icons";
import classNames from 'classnames';
import LoginPage from "./LoginPage";
import {JobCategoryQuestions, JobViewComponent} from "./JobDetailsPage";
import NumberFormat from "react-number-format";


function MainCategory({id, categories}) {
    const [t] = useTranslation();

    return <div className="main-category">
        <div className="title is-4">{t("jobMainCategory." + id)}</div>

        <div className="sub-categories">
            {categories.map(cat => {
                return <NavLink key={cat} to={`/create-job?category=${cat}`}>
                    <div className="sub-category">{t("jobcategory." + cat)}</div>
                </NavLink>

            })}
        </div>
    </div>
}

function CategorySelector() {

    const form = useForm();
    const loadCategoriesAction = useLoadAction(() => API.fetchCategories());
    const [t] = useTranslation();
    if (!loadCategoriesAction.result) return null;
    const categories = loadCategoriesAction.result;

    const {category} = form.values;

    if (category) {
        return <Redirect to={`/create-job?category=${category}`} push={true}/>
    }
    return (
        <div className="create-job page container">
            <Form form={form}>
                <div className="title is-flex is-centered">{t("job.chooseJobCategory")}</div>
                {/*
                <SelectField name="category" options={categories.map(mainCat => {
                    return {
                        label: t("jobMainCategory." + mainCat.mainCategory),
                        options: mainCat.categories.map(cat => ({label: t("jobcategory." + cat), value: cat}))
                    }
                })}/>
*/}

                <div className="category-selector columns">
                    {categories.map(cat => <div key={cat.mainCategory} className="column is-one-third">
                        <MainCategory id={cat.mainCategory}
                                      categories={cat.categories}></MainCategory>
                    </div>)}
                </div>
            </Form>

        </div>
    )
}

function addressValidator(address) {
    const {city, street, streetNumber, zipCode} = address;
    if (!city || !street || !streetNumber || !zipCode) {
        throw {error: 'job.giveMorePreciseAddress'}
    }
}

function Step2({questionsForm, form, questions, category, pricingType, prev, next}) {
    const [t] = useTranslation();

    const [computedPrice, setComputedPrice] = useState();
    const address = form.values.address;

    useEffect(() => {
        window.scrollTo(0,0);
    },[])

    const workTime = {
        name: "workTime",
        label: pricingType === 'computedFullPrice' ? t("job.workTime") : t("job.workTime_not_computed"),
        required: pricingType === "pricePerHour",
        blurInputOnSelect: true
    };


    const workTimeOptions = range(1, 16).map(hour => ({value: hour, label: hour + " " + t('hour'), required: false}));

    const paymentMethod = form.values.paymentMethod;

    const shortDescription = {
        name: "shortDescription",
        label: t("job.shortDescription"),
        required: false
    }

  //  console.log("Form:", form);

    useEffect(() => {
        if (address) {
            API.priceForRequest({
                ...form.values, ...questionsForm.values,
                categoryId: category.id
            }).then(computedPrice => {
                setComputedPrice(computedPrice);
            });
        }

    }, [address, form.values.workTime]);

    useEffect(() => {
        if (computedPrice && paymentMethod) {
            form.addValues({
                computedPrice: computedPrice[paymentMethod],
                computedHours: computedPrice.hours
            })

        }
    }, [computedPrice, paymentMethod])

    const goNext = async () => {
        if (await form.validate()) {
            next();
        }
    }

    if (!computedPrice) {
        return null;
    }

    const price = computedPrice[paymentMethod];

    return (
        <div>
            <div className="wizard-buttons">
                <button className="button is-outlined" onClick={prev}>
                    {t('back')}
                </button>
            </div>

            <div className="columns">
                <div className="column">
                    {/*
                    <NumberField label={t('job.ourComputedPrice')}
                                 name="ourComputedPrice"
                                 value={computedPrice.hours}
                                 readOnly={true}/>
*/}

                    {pricingType === 'computedFullPrice' && <>
                        <NumberField label={t('job.computedHours')}
                                     info={t('job.computedHours_info')}
                                     name="computedHours"
                                     value={computedPrice.hours}
                                     suffix={" " + t('hour')}
                                     readOnly={true}/>

                        <NumberField label={t('job.computedPrice')}
                                     name="computedPrice"
                                     info={t('job.computedPrice_info')}
                                     value={price}
                                     help={t('job.computedPrice_detail')}
                                     suffix={" Forint"}
                                     readOnly={true}/>

                    </>}

                    <SelectField {...workTime} options={workTimeOptions}/>
                    <TextAreaField {...shortDescription} rows={3}/>
                </div>
                <div className="column">
                    <PersonalData form={form} computedPrice={computedPrice}/>
                </div>
            </div>

            <div className="buttons">
                <button className="button is-primary" onClick={goNext}>
                    {t('next')}
                </button>
            </div>
        </div>
    )
}


function Step1({category, form, next, questionsForm, questions}) {
    const [t] = useTranslation();
    const [validated, setValidated] = useState(false);


    function hasQuestionsError() {
        return !!questions.find(question => {
            const answer = questionsForm.values[question.identifier];
            return answer === undefined;
        })
    }


    const hasQuestionError = useMemo(() => hasQuestionsError(), [questionsForm])

    const jobStartDate = {
        name: 'jobStartDate',
        label: t('job.jobStartDate'),
        info: t('job.jobStartDate_info'),
        required: true,
        validator: (val) => {
            if (dayjs(val).isBefore(dayjs().startOf('day'))) {
                throw {error: "futureDate"}
            }

            // legalább + 3 nap
            if (dayjs(val).isBefore(dayjs().startOf('day').add(3, 'day'))) {
                throw {error: "futureDate3"}
            }

        }
    };

    const startTime = {
        name: "startTime",
        label: <span className="start-time-label">{t("job.startTime")}</span>,
        required: true

    };

    const startTimeOptions = [];
    range(7, 22).forEach(t => {
        startTimeOptions.push({value: `${t}:00`, label: `${t}:00`});
        startTimeOptions.push({value: `${t}:30`, label: `${t}:30`});
    })


    const goNext = async () => {
        if (validated) {
            setValidated(false);
        }

        const formIsValid = await form.validate();
        const questionsSormIsValid = questionsForm.validate();
        if (formIsValid && questionsSormIsValid) {
            next();
        }

        setValidated(true);
    }


    return (
        <React.Fragment>

            <div className="columns">
                <div className="column">
                    <Section title={t("job.details")}>
                        <div className="field is-horizontal job-start-date">
                            <div className="field-body">
                                <DateField {...jobStartDate}/>
                                <SelectField {...startTime} options={startTimeOptions}/>
                            </div>
                        </div>

                        <AddressField
                            name="address"
                            label={t("profile.giveYourAddress")}
                            required={true}
                            validator={addressValidator}
                            zoom={16}
                            validateOnBlur={false}
                            mapStyle={{height: '250px', width: '100%'}}
                            placeholder={t("job.address")}
                        />
                        <div className="field is-horizontal">
                            <div className="field-body">
                                <NumberField name="floor" label={t("address.floor")}/>
                                <TextField name="door" label={t("address.door")}/>
                            </div>
                        </div>
                    </Section>

                </div>

                <div className="column">

                    <Section title={t("jobcategory." + category.id)}>
                        <JobCategoryQuestions questions={questions}
                                              category={category}
                                              form={questionsForm}
                        />
                    </Section>

                </div>

            </div>

            {validated &&
            <div className="validation-errors">
                {!form.values.address && <div className="validation-error">{t('job.address_required')}</div>}
                {(!form.values.jobStartDate || !form.values.startTime) &&
                <div className="validation-error">{t('job.jobStartDate_required')}</div>}
                {hasQuestionError && <div className="validation-error">{t('job.fillAllQuestions')}</div>}
            </div>}

            {/*<pre>{JSON.stringify(form,null,2)}</pre>*/}

            <div className="buttons">
                <button className="button is-primary" onClick={goNext}>
                    {t('next')}
                </button>
            </div>
        </React.Fragment>

    )
}


function RegistrationForm() {
    const [t] = useTranslation();
    const form = useContext(FormContext);

    const [passwordError, setPasswordError] = useState();

    useEffect(() => {
        if (form.values.password && form.values.passwordConfirm !== undefined && form.values.passwordConfirm != form.values.password) {
            setPasswordError("signup.passwordDoesntMatch")
        } else {
            setPasswordError(null);
        }
    }, [form.values.passwordConfirm, form.values.password])


    return (
        <React.Fragment>
            <TextField name="lastName" label={t("profile.lastName")}/>
            <TextField name="firstName" label={t("profile.firstName")}/>
            <TextField name="email" label={t("profile.email")}/>
            <PhoneNumberField name="phoneNumber" label={t("profile.phoneNumber")} required={true}/>
            <PasswordField name="password" label={t("profile.password")} required={true}/>
            <PasswordField name="passwordConfirm" label={t("profile.passwordConfirm")}/>

            {passwordError && <ErrorComp error={{error: passwordError}}/>}

        </React.Fragment>
    )
}


function LoginOrRegistration({form}) {
    const appContext = useContext(AppContext);
    const [t] = useTranslation();
    const [selectedTab,setSelectedTab] = useState("registration")

    if (appContext.isAuthenticated) {
        return null;
    }
    return (
        <React.Fragment>
            <div className="personal-data-form-selector">
                <div className="tabs is-toggle is-fullwidth is-primary">
                    <ul>
                        <li className={classNames({"is-active":selectedTab === 'registration'})}>
                            <a onClick={() => setSelectedTab("registration")}>
                                <span>{t("job.loginForNewUsers")}</span>
                            </a>
                        </li>

                        <li className={classNames({"is-active":selectedTab === 'login'})}>
                            <a onClick={() => setSelectedTab("login")}>
                                <span>{t("job.loginForRegisteredUsers")}</span>
                            </a>
                        </li>
                    </ul>
                </div>
            </div>

            {selectedTab === 'login' && <LoginPage hideRegSection={true}/>}
            {selectedTab === 'registration' && <RegistrationForm/>}
        </React.Fragment>
    )
}

function PersonalData({form, computedPrice}) {

    const [t] = useTranslation();
    const appContext = useContext(AppContext);

    return (
        <Section title={t("job.personalData")} className="last-page">
            <LoginOrRegistration form={form}/>
            {appContext.isAuthenticated &&
            <PhoneNumberField name="phoneNumber" label={t("profile.phoneNumber")} required={true}/>}
            <TextField name="couponKey" label={t("job.couponKey")} required={false}/>
            {wrapInput({
                name: "paymentMethod",
                label: t("job.paymentMethod"),
            }, <PaymentMethodSelector name="paymentMethod"
                                      computedPrice={computedPrice}
            />)}
            {/*<div>{JSON.stringify(personalDataForm)}</div>*/}
        </Section>
    )

}


function Step({id, name, icon, active, markerClass}) {
    return (
        <li className={classNames("steps-segment", "has-gaps", {"is-active": active})}>
            <a className="has-text-dark">
                      <span className={classNames("steps-marker", markerClass)}>
                        <span className="icon">
                          {icon}
                        </span>
                      </span>
                <div className="steps-content">
                    <p className="heading">{name}</p>
                </div>
            </a>
        </li>

    )
}

function Wizard({activeStep}) {
    return (
        <ul className="steps is-horizontal is-medium is-centered has-content-centered">
            <Step id="step1" name="Munka részletek" active={activeStep === "step1"}/>
            <Step id="step2" name="Személyes adatok" active={activeStep === "step2"}/>
            <Step id="confirmation" name="Megerősítés" active={activeStep === "confirmation"} icon={<Check/>}
                  markerClass="is-hollow"/>
        </ul>
    )
}


function PaymentMethodSelector(props) {
    const [t] = useTranslation();

    const {value, setValue} = useField(props);
    const {computedPrice} = props;
    const hasBarion = computedPrice.barion != null && computedPrice.barion != undefined;
    return (
        <div className="payment-method-selection">
            <div className="payment-method">
                <div className="field">
                    <input className="is-checkradio"
                           type="radio"
                           id="cash"
                           name="paymentMethod"
                           checked={value === "cash" || !value}
                           value="cash"
                           onChange={(e) => {
                               setValue("cash")
                           }}/>
                    <label htmlFor="cash">{t('paymentMethod.cash')}</label>
                    {computedPrice && <span className="computed-price">
                        {computedPrice.cash ?
                            <NumberFormat value={computedPrice.cash} displayType="text" decimalScale={0}
                                          thousandSeparator={" "} suffix={" Ft"}/> :
                            <span>{t("job.calculationInProgress")}</span>}
                    </span>}
                </div>
            </div>

            {/*
            {hasBarion &&
            <div className="payment-method">
                <div className="field" onClick={() => {
                    setValue("barion")
                }}>
                    <input className="is-checkradio"
                           type="radio"
                           id="barion"
                           name="paymentMethod"
                           value="barion"
                           checked={value === "barion"}
                           onChange={() => setValue("barion")}/>
                    <label htmlFor="barion">{t('paymentMethod.barion')}</label>
                    {computedPrice && <span className="computed-price">
                    {computedPrice.barion ? <NumberFormat value={computedPrice.barion} displayType="text" decimalScale={0}
                                                          thousandSeparator={" "}
                                                          suffix={" Ft"}/> : <span>{t("job.calculationInProgress")}</span>
                    }                    </span>}
                    <a href="https://www.barion.com" target="_blank">
                        <img src={BarionLogo}/>
                    </a>

                </div>
            </div>}
*/}
        </div>

    )
}

function CreateJobPageForm({personalData, categoryId}) {
    const [t] = useTranslation();
    const appCtx = useContext(AppContext);

    const loadQuestionsAction = useLoadAction(() => API.fetchCategoryQuestions({categoryId}));
    const form = useForm({
        address: appCtx.user && appCtx.user.address ? appCtx.user.address : null,
        paymentMethod: "cash",
        phoneNumber: appCtx.user && appCtx.user.phoneNumber ? appCtx.user.phoneNumber : null
    });
    const questionsForm = useForm();
    const [activeStep, setActiveStep] = useState("step1");

    const requestProposalAction = useAction(async () => {


        if (await form.validate() && await questionsForm.validate()) {
            const {floor, door, address, paymentMethod, ...jobRequest} = form.values;

            const res = await API.requestProposal(
                {
                    ...form.values,
                    address: {...address, floor, door},
                    questions: questionsForm.values,
                    categoryId: categoryId
                });

            await appCtx.fetchStatus();

            if (paymentMethod === 'barion') {
                let result = await API.startPayment(res.id);
                if (result.paymentURL) {
                    window.location = result.paymentURL;
                    return;
                }
            }

            return res.id;
        }
    });


    useEffect(() => {
        requestProposalAction.setError(null)
    }, [activeStep])

    useEffect(() => {
        form.setValue('phoneNumber', appCtx.user && appCtx.user.phoneNumber ? appCtx.user.phoneNumber : null)
    }, [appCtx.user])


    if (!loadQuestionsAction.result) return null;

    const questions = loadQuestionsAction.result.questions;
    const pricingType = loadQuestionsAction.result.pricingType;
    const category = loadQuestionsAction.category || {id: categoryId}; //FIXME


    if (requestProposalAction.result) {
        return <Redirect to="/my-job-requests"/>
    }

    return (
        <div className="create-job page container">
            <Form values={personalData} form={form}>
                <Wizard activeStep={activeStep}/>
                {activeStep === "step1" && <Step1 category={category}
                                                  form={form}
                                                  questionsForm={questionsForm}
                                                  questions={questions}
                                                  next={() => setActiveStep("step2")}/>}
                {activeStep === "step2" && <Step2 form={form}
                                                  questionsForm={questionsForm}
                                                  category={category}
                                                  pricingType={pricingType}
                                                  prev={() => setActiveStep("step1")}
                                                  next={() => setActiveStep("confirmation")}/>}
                {activeStep === "confirmation" &&
                <div>
                    <div className="wizard-buttons">
                        <button className="button is-outlined" onClick={() => setActiveStep("step2")}>
                            {t('back')}
                        </button>
                    </div>

                    <JobViewComponent job={{
                        ...form.values,
                        category: {id: categoryId},
                        jobAnswers: questionsForm.values
                    }}
                                      showMessages={false}
                                      questions={questions}/>

                </div>
                }

                {activeStep === "confirmation" &&
                <ActionButton className="is-primary"
                              disabled={requestProposalAction.result}
                              label={t(form.values.paymentMethod === 'barion' ? "job.confirmationAndPayment" : "job.confirmation")}
                              action={requestProposalAction}/>}

                <ErrorComp error={requestProposalAction.error}/>
            </Form>
        </div>
    )
}


export default function CreateJobPage(props) {


    const categoryId = new URLSearchParams(props.location.search).get("category");
    let appContext = useContext(AppContext);
    const user = appContext.user;
    const personalData = user ? {
        firstName: user.firstName,
        lastName: user.lastName,
        phoneNumber: user.phoneNumber,
        paymentMethod: "barion"
    } : {};


    useEffect(() => {
        appContext.setShowMenu(false);
        return () => appContext.setShowMenu(true);
    },[])

    if (!categoryId) {
        return <CategorySelector/>;
    } else {
        return <CreateJobPageForm personalData={personalData}
                                  categoryId={categoryId}/>
    }



}