import React from 'react';
import classNames from 'classnames';
import {UserCircle} from './Icons'

/**
 *
 * @param props {{title,className}}
 * @returns {*}
 * @constructor
 */
export function Section(props) {
    const {title, className, ...otherProps} = props;

    return (
        <div className={classNames('section', className)} {...otherProps}>
            {title && <div className="title is-5 section-title">{title}</div>}
            {props.children}
        </div>)
}

function getNameAbbrev(name) {
    return name.split(" ",2).map(part => part[0]).join("");
}


export function Avatar({profilePictureId,name,className,showTooltip}) {
    return (
        <div className={classNames("avatar",{tooltip:showTooltip},className)} data-tooltip={name}>
            {profilePictureId ?
                <img className="img" src={`/api/avatar/${profilePictureId}`}/> :
                <div className="img">
                    {getNameAbbrev(name)}
                </div>}
        </div>
    )
}