import React from 'react';
import {ReactComponent as Star} from '../icons/star-solid.svg';
import {ReactComponent as EmptyStar} from '../icons/star-regular.svg';
import {ReactComponent as HalfStar} from '../icons/star-half-alt-solid.svg';
import classNames from 'classnames';
import {range} from "../utils";


export default function Rating({max,value,onChange,readOnly}) {
    return (
        <div className={classNames("rating",{disabled : !onChange})}>
            {range(1,max).map(i => i <= value ? <Star key={i} onClick={() => !readOnly && onChange && onChange(i)} className="icon"/> :<EmptyStar key={i} onClick={() =>!readOnly && onChange && onChange(i)} className="icon"/>)}
        </div>
    )
}