import React, {useEffect, useImperativeHandle, useState} from 'react';
import ReactDOM from 'react-dom';
import classNames from 'classnames';

const modalContainer = document.getElementById('modal');

export const ModalContext = React.createContext();

function ModalWithRef(props, ref) {
    const {children} = props
    const [opened, setOpened] = useState(props.opened);

    useEffect(() => {
        if (props.opened != opened) {
            setOpened(props.opened);
        }
    }, [props.opened])


    function close() {
        if (props.onClose) {
            props.onClose();
        }
        setOpened(false);
    }

    function open() {
        setOpened(true);
    }

    useImperativeHandle(ref, function () {
        return {open, close};
    });

    if (!opened) {
        return null;
    }

    return ReactDOM.createPortal(
        <div className={classNames("modal is-active", props.className)}>
            <div className="modal-background"></div>
            <div className="modal-content box">
                <button className="modal-close is-pulled-right" aria-label="close"
                        onClick={() => close()}></button>

                <ModalContext.Provider value={{open,close}}>
                    {children}
                </ModalContext.Provider>

            </div>
        </div>
        , modalContainer);
}

export default React.forwardRef(ModalWithRef);