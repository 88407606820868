import React, {useEffect} from 'react';
import IconMegbiz from '../../images/landing/ikon_megbiz.png';
import IconPozitiv from '../../images/landing/ikon_pozitiv.png';
import IconMinoseg from '../../images/landing/ikon_minoseg.png';
import PictureBoros from '../../images/landing/boros.jpg';
import PictureKertesz from '../../images/landing/kertesz.jpg';
import Arrow from '../../images/landing/arrow.png';
import Feladat from '../../images/landing/ikon_feladat.png';
import Kenyelem from '../../images/landing/ikon_kenyelem.png';
import Megold from '../../images/landing/ikon_megold.png';
import {useLoadAction} from "../../hooks";
import * as API from "../../api";
import {NavLink} from "react-router-dom"

import {useTranslation} from 'react-i18next';
import {joinUsLink} from "../../App";
import EULogo from "../../images/szlogo_felso.jpg";

function CategorySelector({mainCategory, categories}) {
    const [t] = useTranslation();
    return (
        <div className="category">

            <ul className="categories">
                <li className="category-title">
                    <div className="category-name">{t(`jobMainCategory.${mainCategory}`)}</div>
                </li>
                {categories.map(category => (
                    <li tabIndex="0" key={category}>
                        <NavLink to={`/create-job?category=${category}`}>
                            {t(`jobcategory.${category}`)}
                        </NavLink>
                    </li>
                ))}
            </ul>
        </div>
    )
}

function BookNow({mainCategories}) {
    const inHouseWork = mainCategories.find(mc => mc.mainCategory == 'in_house_work');
    const outHouseWork = mainCategories.find(mc => mc.mainCategory == 'out_house_work');
    const children = mainCategories.find(mc => mc.mainCategory == 'children');
    return (
        <div className="book-now-section">
            <div className="container">
                <div className="marketing">
                    <h2>ILYEN EGYSZERŰ A FOLYAMAT</h2>
                </div>

                <div className="booking-steps">
                    <div className="booking-step">
                        <img src={Feladat} className="step-img"/>
                        <div className="step-name">Válaszd ki, amire szükséged van</div>
                        <img src={Arrow} className="arrow"/>
                    </div>


                    <div className="booking-step">
                        <img src={Megold} className="step-img"/>
                        <div className="step-name">Add meg a kérdéseidet</div>
                        <img src={Arrow} className="arrow"/>
                    </div>


                    <div className="booking-step">
                        <img src={Kenyelem} className="step-img"/>
                        <div className="step-name">Értékeld munkatársunkat</div>
                    </div>

                </div>

                <div className="main-categories">
                    <div className="columns">
                        <div className="column">
                            <div className="category-container">
                                <CategorySelector {...inHouseWork}/>
                            </div>

                        </div>
                        <div className="column">
                            <div className="category-container">
                                <CategorySelector {...outHouseWork}/>
                            </div>

                        </div>
                        <div className="column">
                            <div className="category-container">
                                <CategorySelector {...children}/>
                            </div>
                        </div>
                    </div>

                    <div className="columns">
                        <div className="column">
                            <div className="category other-category">
                                <NavLink to={`/create-job?category=JC0107`} role="button">
                                    Egyéb szolgáltatásra van szükségem
                                </NavLink>
                            </div>
                        </div>
                    </div>

                </div>
                {/*
                <div className="category-selector columns">

                    <div className="column">
                        <div className="content">
                            <img src={Feladat}/>
                            <div className="step-name">Válaszd ki, amire szükséged van</div>
                            <CategorySelector {...inHouseWork}/>
                        </div>
                    </div>

                    <div className="column">
                        <div className="content">
                            <img src={Megold}/>

                            <div className="step-name">Add meg a kérdéseidet</div>
                            <CategorySelector {...outHouseWork}/>

                            <NavLink to={`/create-job?category=JC0107`} role="button">
                                Egyéb szolgáltatásra van szükségem
                            </NavLink>
                        </div>
                    </div>

                    <div className="column">
                        <div className="content">
                            <img src={Kenyelem}/>
                            <div className="step-name">Értékeld munkatársunkat</div>
                            <CategorySelector {...children}/>
                        </div>
                    </div>

                    <div className="column">
                        <NavLink to={`/create-job?category=JC0107`} role="button">
                            Egyéb szolgáltatásra van szükségem
                        </NavLink>
                    </div>
                </div>
*/}

            </div>


        </div>
    );
}


function Reasons({focus}) {

    useEffect(() => {

        if (focus) {
            const scrollTarget = document.querySelector(".reasons");
            if (scrollTarget) {
                window.scrollTo(0, scrollTarget.offsetTop);
            }
        }
    }, [])

    return (
        <div className="reasons container">
            <h1 className="choose-us">Válassz minket, mert...</h1>
            <div className="reasons">
                <div className="columns">
                    <div className="column">
                        <div className="reason">
                            <img src={IconMegbiz}/>
                            <div className="reason-title">
                                Megbízhatóság
                            </div>
                            <div className="reason-detail">
                                Ránk minden körülmények között számíthatsz, lelkiismeretesen és felelősségteljesen
                                végezzük számodra a szolgáltatást!
                            </div>
                        </div>

                    </div>
                    <div className="column">
                        <div className="reason">
                            <img src={IconPozitiv}/>
                            <div className="reason-title">
                                Pozitív hozzáállás
                            </div>
                            <div className="reason-detail">
                                Nálunk nincs lehetetlen, mi azt keressük, hogy hogyan "IGEN"! Próbáld ki odaadó
                                hozzáállásunkat!
                            </div>
                        </div>
                    </div>

                    <div className="column">
                        <div className="reason">
                            <img src={IconMinoseg}/>
                            <div className="reason-title">
                                Minőség
                            </div>
                            <div className="reason-detail">
                                Nálunk mértéke a vevő elégedettség, a használhatóság; a környezetbarát hozzáállás; és a
                                rendelkezésre állás (bárhol, bármikor).
                            </div>
                        </div>

                    </div>
                </div>
            </div>
        </div>
    )
}

function getOffset(el) {
    const rect = el.getBoundingClientRect();
    return {
        left: rect.left + window.scrollX,
        top: rect.top + window.scrollY - 100
    };
}

function AboutUsSection({focus}) {

    useEffect(() => {

        if (focus) {
            const scrollTarget = document.querySelector(".home-page-public .book-now");
            if (scrollTarget) {
                scrollTarget.scrollIntoView(true);
            }
        }
    }, []);
    return (
        <div id="aboutUs" className="about-us">
            <div className="container">
                <h1>Tudj meg többet rólunk</h1>
                <p>A különféle szolgáltatásuk megrendelése során, sokszor gondolkodtunk azon, hogy miért nem létezik
                    a szolgáltatásokra olyan megoldás, mint ami az áruk piacán teljesen hatékonyan működik. Miért
                    nem lehet a szolgáltatásokat is egy sztenderd piacon összehasonlítani és megvásárolni? Miért
                    fordítjuk a szabadidőnket olyan dolgokra, hogy már a harmadik takarítónővel egyeztetünk a
                    hónapban? Hogy szervezzük meg, hogy a gyerekeket melyik nap ki vigye edzésre? Vagy miért töltünk
                    több órát telefonálgatással, mikor már a harmadik szerelő sem tudja megjavítani az elromlott
                    fűtést? Ebből jött az ötlet, ami később a küldetésünkké vált: “gyors megoldást kiváló
                    minőségben”.</p>
                <p>Rájöttünk hogy a “közösség” a kulcs. A közösségi lét már nemcsak a virtuális térben valósul meg,
                    a megosztás nem csak képekre, weboldalakra vonatkozik, hanem szinte bármire a gazdaságban. A
                    sharing economy, vagyis a megosztáson alapuló, közösségi gazdaság egy olyan új világméretű
                    mozgalom, mely egyre több iparágban egyre több területen van már jelen. A megosztáson alapuló
                    modell vezérgondolata, hogy egyre kevésbé van jelentősége a tulajdonlásnak és egyre inkább
                    előtérbe kerül az elérés, ami számtalan helyen hatékonyságot, jobb fenntarthatóságot hoz.</p>
                <p>A megosztáson alapuló gazdaság már hazánkban is megjelent néhány területen, azonban a
                    szolgáltatások piacán ez még gyerekcipőben jár, ezért ez az érintettek számára egyértelműen
                    pozitív változásokat hozhat.</p>
            </div>
        </div>
    )
}


export default function PublicHomePage(props) {

    const loadCategoriesAction = useLoadAction(() => API.fetchCategories());
    const [t] = useTranslation();

    if (!loadCategoriesAction.result) {
        return null;
    }

    return (
        <div className="home-page-public page no-margin no-padding">

            <div className="eu-logo-container">
                <NavLink to="/project">
                    <img src={EULogo} alt="" className="eu-logo-header is-pulled-right"/>
                </NavLink>
            </div>

            <BookNow mainCategories={loadCategoriesAction.result}/>

            <Reasons focus={props.why}/>

            <div className="section-with-img">
                <div className="container">
                    <div className="section-with-img-content">
                        <img className="left" src={PictureBoros}/>
                        <div>

                            <h1>Csinálj Magadnak szabadidőt!</h1>
                            <p>Magyarország legjobb szakember GURUjai várnak a megbízásodra, akik garantáltan minőségi
                                munkát végeznek.
                            </p>
                            <p>Add meg a neved, email címed és azt, hogy milyen szolgáltatásra lenne szükséged, amit
                                csak egy tapasztalt GURU-ra bíznál!
                            </p>
                            <p>Jelenleg is szűrjük, teszteljük és ellenőrizzük a GURUjainkat, hogy neked már ne kelljen
                                senkitől kérdezgetned, hogy van-e valakinek megbízható szaki ismerőse, aki jól
                                dolgozik.
                            </p>
                        </div>
                    </div>
                </div>
            </div>

            <div className="book-now">
                <div className="container">
                    <div className="book-now-content">
                        <h1 className="text">Foglalkozz azzal amivel igazán szeretnél!</h1>
                        <NavLink to="/bookNow">
                            <button className="button is-primary">{t('menu.public.bookNow')}</button>
                        </NavLink>
                    </div>
                </div>
            </div>

            <AboutUsSection focus={props.aboutUs}/>

            <div className="section-with-img">
                <div className="container">
                    <div className="section-with-img-content">
                        <div>

                            <h1>Legyél Te is ELITE GURU!</h1>
                            <p>Jelentkezz hozzánk szolgáltatóként és keress a jól végzett munkáddal ELIT bért! Add meg a
                                neved,
                                email címed és azt, hogy milyen munkákban vagy GURU!</p>
                            <p>Az előzetes regisztráció alkalmával jelentkezők az első sikeresen elvégzett munkájuk után
                                5.000Ft-os jóváírást kapnak!</p>
                            <p>FONTOS, hogy valóban minőségi munkát tudj végezni, ne legyen probléma, ha egyedi
                                kéréseket is
                                teljesítened kell, hiszen így tudunk kiemelkedő bért is fizetni a munkádért!</p>
                            <p>Az oldal a munkavállalóknak teljesen ingyenes.</p>
                            <a className="button is-primary" href={joinUsLink}>Regisztrálok!</a>
                        </div>
                        <img className="right" src={PictureKertesz}/>
                    </div>
                </div>
            </div>

        </div>
    );
}

