import React, {useContext, useRef, useState} from 'react';
import {ActionButton, ErrorComp, Form, PasswordField, TextField, useForm} from "../components/Form";
import {useAction} from "../hooks";
import * as Api from "../api";
import {useTranslation} from 'react-i18next';
import {Redirect} from "react-router-dom";
import {AppContext} from "../AppRouter";
import FacebookLogin from 'react-facebook-login';
import {Facebook, Google} from '../components/Icons'
import GoogleLogin from 'react-google-login';
import {SignUpMethodSelector} from "./SignUpPage";
import Modal from "../components/Modal";
import {emailAddress} from "../components/validators";

import classNames from 'classnames'

const googleClientId = "858670559394-8188orclbnj7153tfqkpmglrug6cm8in.apps.googleusercontent.com";


export function FacebookLoginButton({isDisabled, isProcessing, callback}) {
    const [t] = useTranslation();

    return <FacebookLogin
        appId="1068183363368908"
        textButton={t('login.facebook')}
        autoLoad={false}
        fields="name,email"
        isDisabled={isDisabled}
        isProcessing={isProcessing}
        icon={<Facebook className="svg-icon is-left"/>}
        cssClass="button fb-login"
        callback={callback}/>

}

export function GoogleLoginButton({onSuccess, onFailure, label}) {

    const [loading, setLoading] = useState(false);

    async function successCallback(response) {

        await onSuccess(response);

        setLoading(false);
    }

    async function failureCallback(response) {
        await onFailure(response);
        setLoading(false);
    }


    return <GoogleLogin
        clientId={googleClientId}
        buttonText="Login"
        className="google-login"
        onSuccess={successCallback}
        onFailure={failureCallback}
        render={renderProps => (
            <button className={classNames("button google-login", {"is-loading": loading})} onClick={(e) => {
                setLoading(true);
                renderProps.onClick();
            }}>
                <Google className="svg-icon is-left"/>
                {label}
            </button>
        )}
    />


}


function ForgottenPasswordComponent({close, email}) {
    const [t] = useTranslation();
    const form = useForm({email})

    const sendLostPasswordAction = useAction(async () => {
        if (await form.validate()) {
            await Api.lostPassword(form.values.email);
            return true;
        }
    })

    let modalContent;
    if (sendLostPasswordAction.result) {
        modalContent = <div className="email-sent">{t('login.forgottenEmailSent')}</div>;
    } else {
        modalContent = (
            <Form form={form}>
                <TextField name="email" label={t("login.giveYourEmail")} autoFocus={true} autoComplete="username"
                           required={true} validator={emailAddress}/>

                <div className="buttons">
                    <ActionButton className="is-primary" action={sendLostPasswordAction}
                                  label={t('login.sendForgottenPassword')}/>
                </div>

                {sendLostPasswordAction.error && <ErrorComp
                    error={sendLostPasswordAction.error.error ? sendLostPasswordAction.error : {error: "login.couldntSendEmail"}}/>}

            </Form>
        )
    }
    return (
        <Modal opened={true} onClose={close}>
            <div className="box">
                {modalContent}
            </div>
        </Modal>

    );
}

export default function LoginPage(props) {

    const [t] = useTranslation();
    const appContext = useContext(AppContext);
    const form = useForm();
    const fbData = useRef();
    const googleData = useRef();

    const [showForgotPassword, setShowForgotPassword] = useState(false);

    const loginAction = useAction(async () => {
        const user = await Api.authenticate(form.values);

        if (!user) {
            throw {error: "login_error"}
        }

        await appContext.fetchStatus();

        if (props.onSuccess) {
            await props.onSuccess();
        }

        return user;
    });


    const fbLoginAction = useAction(async () => {
        const user = await Api.authenticate({accessToken: fbData.current.accessToken, authType: 'FACEBOOK'});

        if (!user) {
            throw {error: "login_error"}
        }

        await appContext.fetchStatus()
        if (props.onSuccess) {
            await props.onSuccess();
        }

        return user;
    });


    const googleLoginAction = useAction(async () => {
        try {
            const user = await Api.authenticate({accessToken: googleData.current.accessToken, authType: 'GOOGLE'});

            if (!user) {
                throw {error: "login_error"}
            }

            await appContext.fetchStatus()
            if (props.onSuccess) {
                await props.onSuccess();
            }

            return user;

        } catch (e) {
            throw {error: "login_error"}
        }


    });

    const signUpRef = useRef();

    if (!props.onSuccess && (loginAction.result || fbLoginAction.result || googleLoginAction.result)) {
        return <Redirect
            to={props.location && props.location.state && props.location.state.from ? props.location.state.from : "/"}/>
    }

    const isSuccessfulReg = "successfulRegistration" === appContext.routerProps.location.state;

    return (
        <div className="login-page">
            <div className="">
                <div className="content">
                    <Form form={form}>
                        <TextField name="email" label={t("login.email")} autoFocus={true} autoComplete="username"/>
                        <PasswordField name="password" label={t("login.password")} autoComplete="current-password"/>
                    </Form>
                    <div className="buttons">
                        <ActionButton action={loginAction} label={t('login.login')} className="is-primary"/>


                        {/*
                        <FacebookLoginButton
                            isDisabled={fbLoginAction.isLoading}
                            isProcessing={fbLoginAction.isLoading}
                            callback={(response) => {
                                fbData.current = response;
                                fbLoginAction.run();
                            }}/>
*/}


                        <GoogleLoginButton
                            label={t('login.google')}
                            onSuccess={async (response) => {
                                googleData.current = response;
                                await googleLoginAction.run();
                            }}
                            onFailure={console.log}
                        />

                    </div>

                    <ErrorComp error={loginAction.error || googleLoginAction.error}/>

                    {!props.hideRegSection && <>
                        <SignUpMethodSelector ref={signUpRef}/>

                        <a href="#" onClick={() => setShowForgotPassword(true)}>{t('login.forgottenPassword')}</a>

                        <div className="stroked">
                            <span>Vagy</span>
                        </div>
                        <div className="buttons">
                            <button className="button" onClick={() => {
                                return signUpRef.current.open();
                            }}>
                                Regisztrálok
                            </button>
                        </div>

                        <Modal opened={isSuccessfulReg}>
                            <div className="box">
                                Köszönjük regisztrációját!
                            </div>

                        </Modal>
                    </>
                    }

                    {showForgotPassword && <ForgottenPasswordComponent close={() => setShowForgotPassword(false)}
                                                                       email={form.values.email}/>}


                </div>
            </div>

        </div>
    )
}