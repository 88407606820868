import React, {useContext, useState} from 'react';
import dayjs from 'dayjs';
import {partition, range} from "../utils";
import {ReactComponent as Arrow} from '../icons/right-chevron.svg';
import locale_hu from 'dayjs/locale/hu'
import classNames from 'classnames';

dayjs.locale('hu');


function YearsView(props) {
    const {currentDate, setCurrentDate, setView,minDate,maxDate} = useContext(CalendarContext);
    const currentYear = currentDate.year();
    const [currentDecade, setCurrentDecade] = useState(currentYear - (currentYear % 10))
    return (
        <div className="calendar-selector-view">
            <div className="calendar-header">

                <button className="calendar-arrow"
                        onClick={() => currentDecade - 10 >= minDate.year() && setCurrentDecade(currentDecade - 10)}>
                    <Arrow className="cal-icon left"/>
                </button>

                <div className="calendar-header-center">
                    {currentDecade + " - " + (currentDecade + 9)}
                </div>

                <button className="calendar-arrow"
                        onClick={() => (!maxDate || currentDecade + 10 <= maxDate.year()) && setCurrentDecade(currentDecade + 10)}>
                    <Arrow className="cal-icon right"/>
                </button>

            </div>

            <div className="calendar-selector-view-content">
                {range(Math.max(currentDecade,minDate.year()), Math.min(currentDecade + 9,maxDate ? maxDate.year() : currentDecade + 9)).map(year => <div key={year}
                                                                          className={classNames("calendar-selector-view-content-item", {"selected": props.highlightCurrentDate && year === currentYear})}
                                                                          onClick={() => {
                                                                              setCurrentDate(currentDate.set("year",year));
                                                                              setView("months");
                                                                          }}>{year}</div>)}
            </div>
        </div>
    );
}

function MonthsView(props) {

    const {currentDate, setCurrentDate, setView} = useContext(CalendarContext);
    const currentYear = currentDate.year();

    return (
        <div className="calendar-selector-view">
            <div className="calendar-header">

                <button className="calendar-arrow"
                        onClick={() => setCurrentDate(currentDate.subtract(1,"year"))}>
                    <Arrow className="cal-icon left"/>

                </button>

                <div className="calendar-header-center" onClick={() => setView("years")}>
                    {currentYear}
                </div>

                <button className="calendar-arrow"
                        onClick={() => currentDate.add(1,"year")}>
                    <Arrow className="cal-icon right"/>
                </button>

            </div>
            <div className="calendar-selector-view-content">
                {locale_hu.monthsShort.map((month, idx) => <div key={month}
                                                                className={classNames("calendar-selector-view-content-item", {"selected": props.highlightCurrentDate &&  idx === currentDate.month()})}
                                                                onClick={() => {
                                                                    setCurrentDate(currentDate.set('month', idx));
                                                                    setView("days");
                                                                }}>{month}</div>)}
            </div>
        </div>
    )
}

export function Day({currentMonth, day, className, onClick}) {
    const {currentDate,highlightCurrentDate} = useContext(CalendarContext);
    return (
        <div
            onClick={onClick}
            className={classNames("calendar-day",
                {
                    'is-disabled': currentMonth.month() !== day.month(),
                    'current': day.startOf('day').isSame(dayjs().startOf('day')),
                    'selected': highlightCurrentDate && day.startOf('day').isSame(currentDate.startOf('day'))
                },
                className)}
        >
            {day.date()}
        </div>
    );
}

/**
 *
 * @param month
 * @param events [{date?,type?,desc,link?,color?}]
 * @returns {*}
 * @constructor
 */

export function DaysView({events, dayRenderer, input}) {
    const {currentDate, setCurrentDate, setView} = useContext(CalendarContext);
    const currentMonth = dayjs(currentDate).startOf("month")
    const firstDay = getFirstDayOnCalendar(currentMonth);
    const lastDay = getLastDayOnCalendar(currentMonth);
    const numOfDays = lastDay.diff(firstDay, 'day');

    return (
        <div className="calendar calendar-days-view is-unselectable">
            <div className="calendar-header" onClick={(e) => {
                if (input) {
                    input.focus();
                }
            }}>
                <button className="calendar-arrow"
                        onClick={() => {
                            const prevMonth = currentDate.subtract(1, 'month');
                            setCurrentDate(prevMonth);
                        }}>
                    <Arrow className="cal-icon left"/>

                </button>

                <div className="calendar-header-center"
                     onClick={() => setView("months")}>
                    {currentDate.format("MMMM YYYY")}
                </div>
                <button className="calendar-arrow"
                        onClick={() => {
                            const nextMonth = currentDate.add(1, 'month');
                            setCurrentDate(nextMonth);
                        }}>
                    <Arrow className="cal-icon right"/>
                </button>

            </div>

            <div className="calendar-days">
                {[1, 2, 3, 4, 5, 6, 0].map((d, idx) => <div key={idx}>{locale_hu.weekdaysMin[d]}</div>)}
            </div>

            <div className="calendar-weeks">
                {partition(range(0, numOfDays), 7).map((week, idx) => {
                    return (
                        <div key={idx} className="calendar-week">
                            {week.map((day, idx) => {

                                const currDay = firstDay.add(day, 'day');
                                if (dayRenderer) {
                                    return dayRenderer({day: currDay, currentMonth})
                                }

                                return <Day key={idx}
                                            currentMonth={currentMonth}
                                            day={currDay}
                                />
                            })}
                        </div>);

                })}
            </div>
        </div>
    )
}


const CalendarContext = React.createContext({});

/**
 *
 * @param props {{currentDate,minDate,maxDate,view,highlightCurrentDate}}
 * @returns {*}
 * @constructor
 */
export function Calendar(props) {
    const [view, setView] = useState(props.view || "days");
    const [currentDate, _setCurrentDate] = useState(dayjs(props.currentDate));
    const {highlightCurrentDate,minDate = dayjs("1900-01-01"),maxDate} = props;

    function setCurrentDate(date) {
        _setCurrentDate(date);
        if (props.onCurrentDateChanged) {
            props.onCurrentDateChanged(date);
        }

    }

    const boundedSetCurrentDate = (nextDate) => {
        if (!nextDate.isBefore(minDate) && (maxDate === undefined || !nextDate.isAfter(maxDate))) {
            setCurrentDate(nextDate);
        }
    }

    return (
        <CalendarContext.Provider value={{
            setView,
            minDate,maxDate,
            setCurrentDate:boundedSetCurrentDate,
            currentDate,highlightCurrentDate}}>
            <div className="calendar">
                {view === "days" && <DaysView {...props}/>}
                {view === "months" && <MonthsView {...props}/>}
                {view === "years" && <YearsView {...props}/>}
            </div>
        </CalendarContext.Provider>

    );
}


function getFirstDayOnCalendar(date) {
    let firstDay = dayjs(date).startOf('month').startOf("week").startOf("day");
    return firstDay;
}

function getLastDayOnCalendar(date) {
    let lastDay = dayjs(date).endOf('month').endOf("week").startOf('day');
    return lastDay;
}

export function getEventsForDay(day, events) {
    if (!events) return null;
    const res = events.filter(event => day.isSame(dayjs(event.date).startOf('day')));

    return res.length > 0 ? res : null;
}