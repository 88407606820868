import React, {useState} from 'react'
import {useTranslation} from 'react-i18next';
import dayjs from "dayjs";
import classNames from 'classnames';
import * as API from '../api'
import {useLoadAction} from "../hooks";
import LoadingIndicator from "../components/LoadingIndicator";
import {ErrorComp} from "../components/Form";
import {Avatar} from "../components/other";
import {NavLink} from "react-router-dom"


function MessageCount({count}) {
    return <div className="messages-badge">
        <svg fill="currentColor" width="24" height="24" stroke="currentColor" strokeWidth="1" strokeLinecap="round"
             strokeLinejoin="round">
            <path d="M21 15a2 2 0 0 1-2 2H7l-4 4V5a2 2 0 0 1 2-2h14a2 2 0 0 1 2 2z"/>
            <text x="50%" y="55%" className="text" textAnchor="middle">{count}</text>
        </svg>
    </div>
}

function MessageComp(props) {
    const {from, message, date,} = props.message;
    return (
        <div className={classNames("job-message", {flipped: props.flipped})}>
            <div className="job-message-content">
                <div className="job-message-msg">
                    {message}
                    <svg className="corner" width="16" height="16">
                        <path d="M 0 0 V 16 H 16 M 0 0"/>
                    </svg>
                    <Avatar className="job-message-avatar" profilePictureId={from.profilePictureId}
                            name={from.fullName}/>
                </div>
                <div className="job-message-date">{dayjs(date).format('YYYY.MM.DD HH:mm')}</div>
            </div>

        </div>
    )
}

export function MessagesForJobApply({jobId, disableNewMessages}) {
    const [t] = useTranslation();

    const loadMessagesAction = useLoadAction(() => API.fetchMessagesForJobApply({jobId}));
    const [sendInProgress, setSendInProgress] = useState(false);
    const [inputValue, setInputValue] = useState("");
    const [error, setError] = useState("");

    if (loadMessagesAction.isLoading) {
        return <LoadingIndicator/>;
    }
    const messages = loadMessagesAction.result;
    if (!messages) {
        return null;
    }

    return (
        <div>
            <div className="messages">
                {(messages || []).map((msg, i) => <MessageComp key={msg.date} message={msg}
                                                               flipped={msg.commentedByRequester}/>)}

                {(!messages || messages.length === 0) && <div className="msg">{t("messages.noMessage")}</div>}
            </div>
            {!disableNewMessages &&
            <div className="messages-controls">
                <div className="field">
                    <div className={classNames("control", {"is-loading": sendInProgress})}>
                                                        <textarea autoFocus={false} name="message" rows="3"
                                                                  value={inputValue}
                                                                  onChange={(e) => setInputValue(e.target.value)}
                                                                  className={classNames("textarea", {"is-danger": error})}>
                                                        </textarea>
                        <p className="help is-danger">{error}</p>
                    </div>
                    <ErrorComp error={error}/>
                </div>
                <div className="buttons">
                    <button
                        disabled={!inputValue.trim()}
                        className={classNames("button is-primary", {"is-loading": sendInProgress})}
                        onClick={async () => {
                            setError(null)
                            setSendInProgress(true);
                            const newMessage = await API.sendMessageToJob({jobId: jobId, message: inputValue.trim()})
                            let newResult = {...loadMessagesAction, result: [...messages, newMessage]};
                            if (newMessage) {
                                loadMessagesAction.setState(newResult);
                                setInputValue("");
                            }
                            setSendInProgress(false);

                        }}>
                        {t('messages.send')}
                    </button>
                </div>
            </div>}
        </div>
    );

}

function MessagesList({comments}) {
    const [t] = useTranslation();

    if (!comments) return null;

    if (comments.length === 0) {
        return <div className="empty-list">{t('emptyList')}</div>;
    }

    return (
        <div className="messages-list">
            <table className="table expandable-list is-fullwidth">
                <tbody>
                {comments.map((comment) => {
                    const {
                        id,
                        jobAddress,
                        jobMainCategory,
                        jobCategory,
                        from,
                        date,
                        jobId,
                        message,
                    } = comment;
                    return (
                        <tr key={comment.date}>
                            <td>
                                <div className="is-flex">
                                    <Avatar profilePictureId={from.profilePictureId} name={from.fullName}/>
                                    <div className="category-and-address">
                                        <div>
                                            <strong>{t('jobMainCategory.' + jobMainCategory)},{t('jobcategory.' + jobCategory)}</strong>
                                        </div>
                                        <div>{jobAddress}</div>
                                    </div>
                                </div>

                            </td>
                            <td style={{width: "50%"}}>{message}</td>
                            <td>{dayjs(date).format('YYYY.MM.DD HH:mm')}</td>
                            <td className="action">
                                <NavLink to={"/job/" + jobId}>
                                    <button className="button is-primary">
                                        {t('list.open')}
                                    </button>
                                </NavLink>

                            </td>
                        </tr>
                    )
                })}
                </tbody>
            </table>
        </div>
    )
}


export default function MessagesPage() {
    const [t] = useTranslation();
    const commentsLoader = useLoadAction(() => API.fetchLatestComments());
    if (commentsLoader.isLoading) {
        return <LoadingIndicator delay={150}/>
    } else if (commentsLoader.result) {
        return (
            <div className="messages-page">
                <div className="container">
                    <div className="page-title title is-4">{t('messages.title')}</div>
                    <MessagesList comments={commentsLoader.result.data.content}/>
                </div>

            </div>
        )

    } else {
        return null;
    }

}