export function range(start, end) {
    return [...Array(end - start + 1)].map((_, i) => start + i);
}

export function partition(arr, length) {
    const result = [];
    for (let i = 0; i < arr.length; i++) {
        if (i % length === 0) result.push([]);
        result[result.length - 1].push(arr[i]);
    }
    return result;
}

export function sleep(ms) {
    return new Promise((resolve) => setTimeout(resolve,ms));
}