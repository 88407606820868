import React, {useContext, useState} from 'react';
import classNames from 'classnames';
import {useTranslation} from 'react-i18next';
import {NavLink} from "react-router-dom";
import Logo from '../images/guru_logo.png';
import UserIcon from '../images/landing/ikon_user.png';
import * as API from '../api';
import {AppContext} from "../AppRouter";
import {Avatar} from "./other";
import {useHover} from "../hooks";
import {Bars} from "./Icons";
import {joinUsLink} from "../App";

function PublicMenu(props) {
    const [expanded, setExpanded] = useState(false);
    const [t, i18n] = useTranslation();
    const {showMenu,setShowMenu} = useContext(AppContext);

    function closeMenu() {
        setExpanded(false);
    }

    return (
        <nav className="navbar is-fixed-top" role="navigation" aria-label="main navigation">
            <div className="menu-container container">
                <div className="navbar-brand">
                    <NavLink className="navbar-item" to="/">
                        <img src={Logo}/>
                    </NavLink>

                    <a role="button"
                       onClick={() => setExpanded(!expanded)}
                       className={classNames("navbar-burger burger", {"is-active": expanded,"hide-menu":!showMenu})}
                       aria-label="menu" aria-expanded={expanded}
                       data-target="navbar">
                        <Bars className="svg-icon bars"/>
                    </a>
                </div>

                <div id="navbar" className={classNames("navbar-menu", {"is-active": expanded})}>
                    <div className="navbar-end">
                        <NavLink to="/" exact className="navbar-item" onClick={closeMenu}>
                            {t('menu.public.home')}
                        </NavLink>

                        <NavLink to="/why" exact className="navbar-item" onClick={closeMenu}>
                            {t('menu.public.why')}
                        </NavLink>

                        <NavLink to="/aboutUs" className="navbar-item" onClick={closeMenu}>
                            {t('menu.public.aboutUs')}
                        </NavLink>

                        <a href={joinUsLink} className="navbar-item" onClick={closeMenu}>
                            <span>{t('menu.public.joinUs')}</span>
                        </a>

                        <NavLink to="/login" className="navbar-item" onClick={closeMenu}>
                            <img className="login-user-icon" src={UserIcon} alt={t('menu.public.login')}/>
                            {t('menu.public.login')}
                        </NavLink>

                        <NavLink to="/bookNow" className="navbar-item" onClick={closeMenu}>
                            <span className="book-now">{t('menu.public.bookNow')}</span>
                        </NavLink>
                        {/*<a className="navbar-item" href="/#about-us">{t('menu.public.aboutUs')}</a>*/}
                    </div>
                </div>
            </div>

        </nav>
    )

}


function AvatarMenu(props) {
    const appContext = useContext(AppContext);
    const [t, i18n] = useTranslation();
    const [hide, setHide] = useState(false); //Hide if menu was clicked
    const isWorker = appContext.user && appContext.user.workerStatus === 'WORKER';

    function closeMenu() {
        setHide(false);
        props.closeMenu();
    }

    const menuItems = (
        <>
            <NavLink to="/profile" className="navbar-item" onClick={closeMenu}>
                {t('menu.private.profile')}
            </NavLink>
            {isWorker && <NavLink to="/availability" className="navbar-item" onClick={closeMenu}>
                {t('menu.private.availability')}
            </NavLink>}


            {isWorker && <NavLink to="/categories" className="navbar-item" onClick={closeMenu}>
                {t('menu.private.categories')}
            </NavLink>}

            <NavLink to="/login" className="navbar-item" onClick={async () => {
                appContext.logout();
                await API.logout();
            }}>
                {t('menu.private.logout')}
            </NavLink>
        </>
    );

    return (
        <>
            <div className="navbar-item has-dropdown is-hoverable is-hidden-mobile" onMouseEnter={() => setHide(false)}>
                {appContext.user && <div className="avatar-container">
                    <Avatar profilePictureId={appContext.user.profilePictureId}
                            name={appContext.user.fullName}/>
                </div>}

                {!hide && <div className="navbar-dropdown" onClick={() => setHide(true)}>
                    {menuItems}
                </div>}
            </div>
            <div className="is-hidden-desktop">
                {menuItems}
            </div>
        </>

    )
}

function PrivateMenu(props) {
    const [expanded, setExpanded] = useState(false);
    const [t, i18n] = useTranslation();

    const [menuRef, isMenuHovered] = useHover();
    const appContext = useContext(AppContext);

    const isWorker = appContext.user && appContext.user.workerStatus === 'WORKER';
    const isEmployer = appContext.user && appContext.user.employersStatus === 'EMPLOYER';

    function closeMenu() {
        setExpanded(false);
    }

    return (
        <nav className="navbar is-fixed-top" role="navigation" aria-label="main navigation">
            <div className="menu-container container">
                <div className="navbar-brand">
                    <NavLink className="navbar-item" to="/home" onClick={closeMenu}>
                        <img src={Logo}/>
                    </NavLink>

                    <a role="button"
                       onClick={() => setExpanded(!expanded)}
                       className={classNames("navbar-burger burger", {"is-active": expanded,"hide-menu":!appContext.showMenu})}
                       aria-label="menu" aria-expanded={expanded}
                       data-target="navbar">
                        <Bars className="svg-icon bars"/>
                    </a>
                </div>

                <div id="navbar" className={classNames("navbar-menu", {"is-active": expanded})}>
                    <div className="navbar-start">

                        <NavLink to="/" exact className="navbar-item" onClick={closeMenu}>
                            {t('menu.public.home')}
                        </NavLink>

                        {isWorker && <NavLink to="/jobs" className="navbar-item" onClick={closeMenu}>
                            {t('menu.private.jobs')}
                        </NavLink>}

                        {isEmployer && <NavLink to="/my-job-requests" className="navbar-item" onClick={closeMenu}>
                            {t('menu.private.myJobRequests')}
                        </NavLink>}

                        {isWorker && <NavLink to="/finance" className="navbar-item" onClick={closeMenu}>
                            {t('menu.private.finance')}
                        </NavLink>}

                        {!isWorker && <a href="/joinUs" className="navbar-item">
                            <span>{t('menu.public.joinUs')}</span>
                        </a>}


                        <NavLink to="/messages" className="navbar-item" onClick={closeMenu}>
                            {t('menu.private.notifications')}
                        </NavLink>

                    </div>

                    <div className="navbar-end">
                        <AvatarMenu closeMenu={closeMenu}/>
                    </div>
                </div>
            </div>

        </nav>
    )
}

export default function Menu(props) {
    const [expanded, setExpanded] = useState(false);
    const [t, i18n] = useTranslation();
    const appContext = useContext(AppContext);

    return appContext.isAuthenticated ? <PrivateMenu/> : <PublicMenu/>
}