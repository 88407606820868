import React, {forwardRef, useContext, useEffect, useImperativeHandle, useRef, useState} from 'react';
import {useTranslation} from 'react-i18next';
import classNames from "classnames";
import NumberFormat from "react-number-format";
import {usePrevious} from "../hooks";
import Select from "react-select";
import Rating from "./Rating";
import dayjs from 'dayjs';
import {Calendar as CalendarIcon, Info} from "./Icons";
import {Calendar, Day} from "./Calendar";
import * as Validators from "./validators";

export const FormContext = React.createContext({horizontal: false});


export function useField(fieldProps, ref) {

    const {name, validateOnBlur = true} = fieldProps;
    const [t] = useTranslation();
    const form = useContext(FormContext);
    if (!form || !form.values) {
        throw new Error(`useField(${name} ) must be used within Form component`)
    }

    const value = form.values[name];
    const error = form.errors ? form.errors[name] : null;

    useEffect(() => {
        form.addField(fieldProps);
        return () => {
            form.removeField(name);
        }
    }, [])

    function validate() {
        form.validateField(name);
    }

    const onChange = (e) => {
        if (e && e.target && e.target.hasOwnProperty('checked')) {
            form.setValue(name, (e && e.target ? e.target.checked : false));
        } else {
            form.setValue(name, (e && e.target ? e.target.value : e));
        }
    };

    const onBlur = (e) => {
        if (validateOnBlur) {
            validate();
        }
    };

    return {
        value: value,
        error: error,
        onChange: onChange,
        onBlur: onBlur,
        validate: validate,
        setError: (name, error) => form.setError(name, error),
        validator: fieldProps.validator,
        setValue: (val) => {
            form.setValue(name, val);
        }
    }
}

function equals(a, b) {
    return JSON.stringify(a) == JSON.stringify(b)
}

export function useForm(values) {
    const [formValues,setFormValues] = useState(values || {});
    const [formErrors,setFormErrors] = useState({});
    const fields = useRef({});
    const prevValues = usePrevious(values);
    const [t] = useTranslation();
    const [fieldsToValidate,setFieldsToValidate] = useState([])

    useEffect(() => {
        if (fieldsToValidate && fieldsToValidate.length > 0) {
            fieldsToValidate.forEach(fieldName => {
                const error = validateField(fieldName);
                setError(fieldName, error);
            });
            setFieldsToValidate([]);
        }
    },[fieldsToValidate])

    function setValues(values) {
        //console.log("Set values:",values);
        //dispatch({type: 'setValues', values});
        if (formValues !== values) {
            setFormValues(values);
        }
    }

    function addValues(values) {
        //console.log("Set values:",values);
        //dispatch({type: 'setValues', values});
        if (values) {
            setFormValues({...formValues,...values});
        }
    }


    if (prevValues === null && !equals(values, formValues)) {
        setValues(values);
    }

    function setValue(id, value) {
       // console.log("Set value:",id,value);
        const newFormValues = {...formValues,[id]:(value === "" ? null : value)}
        setFormValues(newFormValues);
        const newErrors = {...formErrors};
        delete newErrors[id];
        setFormErrors(newErrors);
    }

    function setError(id, error) {
        const newErrors = {...formErrors};
        if (error) {
            newErrors[id] = error;
        } else {
            delete newErrors[id];
        }

        setFormErrors(newErrors);
    }

    const setErrors = (errors) => setFormErrors(errors);

    function validateField(fieldName) {
        const fieldProps = fields.current[fieldName];
        const error = formErrors ? formErrors[fieldName] : null;
        const value = formValues[fieldName];

        if (error) {
            setError(fieldProps.name, null);
        }

        try {
            if (fieldProps.required) {
                Validators.required(value)
            }

            if (fieldProps.validator) {
                fieldProps.validator(value);
            }
            return null;
        } catch ({error, args}) {
            const errorMsg = t(error, args);
            return errorMsg;
        }

    }

    const validate = () => {
        //console.log("Validate form")
        const validationResult = Object.keys(fields.current).reduce((errors, fieldName) => {
            let field = fields.current[fieldName];
            //  console.log("Validate field:", field);
            const error = validateField(fieldName);
            if (error) {
                errors[fieldName] = error;
            }
            return errors;
        }, {});

        const hasError = Object.keys(validationResult).length !== 0;
        setErrors(hasError ? validationResult : null);
        return !hasError;
    }

    const addField = (fielProps) => {
        fields.current[fielProps.name] = fielProps;
    }

    const removeField = (id) => {
        delete fields.current[id];
    }

    const _validateField = (fieldName) => {
        setFieldsToValidate([fieldName]);
    }


    return {
        values: formValues,
        errors: formErrors,
        addValues,
        setValue,
        setError,
        validate,
        validateField: _validateField,
        addField,
        removeField
    }
}

export function wrapInput(props, input,form) {
    const {error, label, controlClassName, help, info} = props;

    if (form && form.horizontal) {
        return (
            <div className="field is-horizontal">
                {label &&
                <div className="field-label">
                    <label className="label">{label}:</label>
                    {info && <div className="tooltip is-tooltip-multiline" data-tooltip={info}><Info
                        className={classNames("icon info-icon is-right")}/></div>}
                </div>}
                <div className="field-body">
                    <div className="field">

                        <div className={classNames("control", controlClassName)}>
                            {input}
                        </div>
                        {help && <p className="help">{help}</p>}
                        {error && <p className="help is-danger">{error}</p>}
                    </div>
                </div>
            </div>

        )
    }
    return (
        <div className="field">
            {label && <label className="label">
                {label}
                {info && <div className="tooltip is-tooltip-multiline" data-tooltip={info}><Info
                    className={classNames("icon info-icon is-right")}/></div>}
            </label>}
            <div className={classNames("control", controlClassName)}>
                {input}
            </div>
            {help && <p className="help">{help}</p>}
            {error && <p className="help is-danger">{error}</p>}
        </div>

    )
}

export function TextField(props) {
    const {name} = props;
    const {ref, value, error, onChange, onBlur} = useField(props);
    const form = useContext(FormContext);

    return wrapInput({...props, error}, props.readOnly ? value : <input
        ref={ref}
        type="text"
        name={name}
        className={classNames("input", props.className, {"is-danger": error})}
        onChange={onChange}
        onBlur={onBlur}
        value={value || ""}
        autoFocus={props.autoFocus}/>
    ,form);
}

export function NumberField(props) {
    const {name, suffix} = props;
    const {ref, value, error, onChange, onBlur} = useField(props);
    const form = useContext(FormContext);

    return wrapInput({...props, error}, props.readOnly ? <NumberFormat
        value={props.value}
        thousandSeparator={" "}
        name={name}
        allowNegative={false}
        suffix={suffix}
        displayType={"text"}
    /> : (
        <NumberFormat
            ref={ref}
            thousandSeparator={" "}
            allowNegative={false}
            value={props.value}
            suffix={suffix}
            name={name}
            onValueChange={(val) => onChange(val.floatValue || val.value)}
            onBlur={onBlur}
            className="input"
        />
    ),form);
}


const phoneNumberValidator = (val) => {
    if (val && !((val + "").match("^\\+?\\d{11}$"))) {
        throw {error: 'invalidPhoneNumber'}
    }
}

export function PhoneNumberField(props) {
    const {name, placeholder} = props;
    const {ref, value, error, onChange, onBlur} = useField({...props, validator: phoneNumberValidator});
    const form = useContext(FormContext);

    return wrapInput({...props, error}, props.readOnly ? <span>{value}</span> : (
        <NumberFormat
            ref={ref}
            decimalScale={0}
            allowNegative={false}
            format="+## ## ### ####"
            value={value}
            prefix="+"
            placeholder={placeholder || "+36 90 555 5555"}
            name={name}
            onValueChange={(val) => {
                if (value != (val.floatValue || val.value)) {
                    onChange((val.floatValue || val.value) + "");
                }
            }}
            onBlur={(e) => onBlur(e)}
            className="input"
        />
    ),form);
}


export function passwordValidator(value) {
    if (value.length < 6) {
        throw {error: 'invalidPassword'}
    }
}

export function PasswordField(props) {

    const name = props.name
    const {ref, value, error, onChange, onBlur} = useField({...props, validator: props.validator || passwordValidator});
    const form = useContext(FormContext);

    return wrapInput({...props, error}, props.readOnly ? value : <input
        ref={ref}
        type="password"
        name={name}
        placeholder="******"
        className={classNames("input", props.className, {"is-danger": error})}
        onChange={onChange}
        onBlur={onBlur}
        value={value || ""}/>
    ,form)
}


function getSelectValue(props, value) {
    if (props.isMulti) {
        value = value ? (props.options || []).filter(opt => value.indexOf(opt.value) > -1) : null;
    } else {
        value = (props.options || []).find(opt => opt.value === value);
    }
    return value;
}

export function SelectField(props) {
    const [t] = useTranslation();
    const {ref, value: formVal, onChange, error, onBlur} = useField(props);
    let value = getSelectValue(props, formVal);
    const form = useContext(FormContext);

    return wrapInput({...props, error}, props.readOnly ?
        <span>{value ? (props.isMulti ? value.map(opt => opt.label).join(",") : value.label) : ""}</span> : (
            <Select
                ref={ref}
                value={value}
                onChange={(value) => {
                    if (value instanceof Array) {
                        onChange(value.map(val => val.value));
                    } else {
                        onChange(value != null && value != undefined ? value.value : null);
                    }

                }}
                name={props.name}
                onBlur={onBlur}
                placeholder={props.placeholder || t('selectAnOption')}
                options={props.options}
                isMulti={props.isMulti}
                maxMenuHeight={props.maxMenuHeight}
                menuPlacement={props.menuPlacement}
                isClearable={!props.required}
                isSearchable={props.isSearchable || false}
                blurInputOnSelect={props.blurInputOnSelect}/>),
        form);
}

export function TextAreaField(props) {
    const {ref, value, error, onChange, onBlur} = useField(props);
    const form = useContext(FormContext);

    return wrapInput({...props, error}, props.readOnly ? value : <textarea
        ref={ref}
        name={props.name}
        className={props.className || "textarea"}
        className={classNames("textarea", props.className, {"is-danger": error})}
        onChange={onChange}
        rows={props.rows || 3}
        onBlur={onBlur}
        value={value}/>
    ,form);
}

function _RatingField(props, ref) {
    const {value, onChange, error, setValue} = useField(props, ref);
    const form = useContext(FormContext);

    return wrapInput({...props, error}, <Rating ref={ref} max={5} value={value} onChange={setValue}
                                                readOnly={props.readOnly}/>,form)
}

export const RatingField = forwardRef(_RatingField);

export function CheckBoxField(props) {
    const {ref, value, onChange, error, onBlur, setValue} = useField(props);
    const form = useContext(FormContext);

    return wrapInput({...props, error}, <input
        ref={ref}
        type="checkbox"
        name={props.name}
        className={classNames("checkbox", props.className, {"is-danger": error})}
        onChange={(e) => setValue(e.target.checked)}
        value={value || false}
        checked={value || false}
    />,form)
}

function parseDate(d) {
    if (!d) return null;
    try {
        if (typeof d === "string") {
            d = d.replace(new RegExp("\\.", "g"), "-");
        }
        return dayjs(d);
    } catch (e) {
        return null;
    }
}

function formatDate(d) {
    if (d) {
        try {
            return dayjs(d).format("YYYY.MM.DD");
        } catch (e) {
            return null;
        }

    }
    return null;
}

/**
 *
 * @param props {{value,defaultDate,defaultView,minDate,maxDate}}
 * @returns {*}
 * @constructor
 */
export function DateField(props) {

    const {ref, value, error, setValue, validate, onChange} = useField(props);
    const [isOpened, setOpened] = useState(false);
    const [inputVal, setInputVal] = useState(formatDate(parseDate(value)) || "");
    const [prevValue, setPrevValue] = useState(dayjs(value));
    const dateFieldRef = useRef();
    const inputRef = useRef();
    const changed = useRef();

    if (prevValue != value) {
        setInputVal(formatDate(parseDate(value)) || "");
        setPrevValue(value);
        changed.current = false;
    }

    const handleValueChange = (newVal) => {
        changed.current = false;
        if (!dayjs(newVal).isSame(dayjs(value))) {
            setInputVal(formatDate(newVal) || "");
            setValue(newVal && newVal.isValid() ? dayjs(newVal).toDate().getTime() : null);
            validate();

        }
    }

    const handle = e => {

        const el = dateFieldRef.current;
        if (el && !el.contains(e.target)) {
            if (isOpened) {
                setOpened(false);
                validate();
            }

        }
    };
    useEffect(() => {
        if (isOpened) {
            document.addEventListener('touchend', handle, true)
            document.addEventListener('click', handle, true)
        } else {
            document.removeEventListener('touchend', handle, true)
            document.removeEventListener('click', handle, true)
        }
        return () => {
            document.removeEventListener('touchend', handle, true)
            document.removeEventListener('click', handle, true)
        }
    }, [isOpened])

    const form = useContext(FormContext);
    return wrapInput({...props, error, controlClassName: "has-icons-right"},
        props.readOnly ? formatDate(value) : (
            <div className="date-field" ref={dateFieldRef}>
                <div className="date-input">
                    <input type="text"
                           className={classNames("input", props.className, {"is-danger": error})}
                           autoComplete="off"
                           autoCorrect="off"
                           ref={inputRef}
                           name={props.name}
                           onClick={() => setOpened(true)}
                           onFocus={() => setOpened(true)}
                           onKeyDown={(e) => {
                               if (e.keyCode === 9) {
                                   validate();
                               }
                               if (e.keyCode === 9 || e.keyCode === 27) {
                                   setOpened(false);
                               }
                           }}
                           onChange={(e) => {
                               setInputVal(e.target.value);
                               changed.current = true;
                           }}
                           value={inputVal}
                           onBlur={(e) => {
                               if (changed.current || e.keyCode === 9) {
                                   const newVal = parseDate(inputVal);
                                   handleValueChange(newVal);
                               }
                           }}
                    />
                    <span className="icon is-small is-right">
            <CalendarIcon/>
            </span>
                </div>
                {isOpened && <div className="date-picker box">
                    <Calendar input={inputRef.current}
                              currentDate={(value && dayjs(value)) || props.defaultDate || dayjs()}
                              highlightCurrentDate={!!value}
                              view={value ? 'days' : props.defaultView}
                              minDate={props.minDate}
                              maxDate={props.maxDate}
                              dayRenderer={({day, currentMonth}) => {
                                  return <Day
                                      key={day.unix()}
                                      day={day}
                                      onClick={(_) => {
                                          handleValueChange(day);
                                          setOpened(false);
                                      }}
                                      className={classNames({"selected": !!value && dayjs(value).startOf('day').isSame(day)})}
                                      currentMonth={currentMonth}/>;
                              }}/>
                </div>}
            </div>)
    ,form);
}


function _Form({children, horizontal, form, className}, ref) {

    useImperativeHandle(ref, () => form);

    return (
        <div className={classNames("form", className)}>
            <FormContext.Provider value={form}>
                {children}
            </FormContext.Provider>
        </div>
    )
}

export const Form = forwardRef(_Form);

export function Button(props) {
    const {className, ...restProps} = props;
    return (
        <button className={classNames("button", props.className)} {...restProps}>
            {props.icon && <span className="icon">
                      {props.icon}
                    </span>
            }
            <span>{props.label}</span>
        </button>
    )
}

export function ActionButton(props) {
    return (
        <button className={classNames("button", props.className, {"is-loading": props.action.isLoading})}
                onClick={props.action.run}
                disabled={props.disabled}>
            {props.icon && <span className="icon">
                      {props.icon}
                    </span>
            }
            <span>{props.label}</span>
        </button>
    )
}

export function ErrorComp({error}) {
    const [t] = useTranslation();

    if (!error) return null;

    return (
        <div className="error">
            {t(error.error, error.args)}
        </div>
    )
}
