import React, {useState} from 'react';
import NumberFormat from "react-number-format";
import {useLoadAction} from "../hooks";
import * as Api from "../api";
import LoadingIndicator from "../components/LoadingIndicator";
import {useTranslation} from 'react-i18next';
import {ExpandableList} from "../components/List";
import {JobExpandedRow, StateBox} from "./JobsPage";
import {NavLink} from "react-router-dom";
import dayjs from "dayjs";
import {range} from "../utils";
import locale_hu from 'dayjs/locale/hu'
import Rating from "../components/Rating";

export function JobsList({jobs}) {
    const [t] = useTranslation();
    const [expandedJob, setExpanded] = useState();

    if (jobs && jobs.length === 0) {
        return <div className="empty-list">{t('emptyList')}</div>
    }
    return (
        <div className="jobs-list">
            {/*<JobSorting/>*/}
            <ExpandableList rows={jobs}
                            rowRenderer={(job, isExpanded) => {
                                const {id, jobRequestStateLabel, requestState, category, workerRating, address, description,finalPrice, requestUser, jobStartDate,startTime} = job;
                                return (
                                    <>
                                        <td>
                                            <div style={{display: 'flex', alignItems: 'center'}}>
                                                <StateBox state={requestState}/>
                                                <div>
                                                    <div>
                                                        <strong>{category.mainCategoryStr + "," + category.label}</strong>
                                                    </div>
                                                    <div>{requestUser.fullName}</div>
                                                </div>

                                            </div>
                                        </td>
                                        <td>{dayjs(jobStartDate).format('YYYY.MM.DD')} <strong>{startTime}</strong></td>
                                        <td>
                                            <strong>
                                                <FormattedNumber value={finalPrice} suffix={" Ft"} className="total-income"/>
                                            </strong>
                                        </td>
                                        <td>
                                            <Rating max={5} value={workerRating}/>
                                        </td>
                                        <td className="action">
                                            <button className="button is-primary" onClick={(e) => e.stopPropagation()}>
                                                <NavLink to={"/job/" + id}>
                                                    {t('list.open')}
                                                </NavLink>
                                            </button>
                                        </td>
                                    </>
                                )
                            }}
                            expandedRowRenderer={(job) => {
                                return (
                                    <td colSpan="5">
                                        <JobExpandedRow job={job}/>
                                    </td>
                                )
                            }}/>
        </div>
    )
}


function IncomeByMonthComp({incomeByMonth}) {
    return (
        <div className="income-by-month">
            {range(1, 12).map(month => (
                <div className="month">
                    <div className="month-name">{locale_hu.monthsShort[month - 1]}.</div>
                    <div className="month-income">
                        <FormattedNumber value={incomeByMonth[month]} suffix={" Ft"} className="total-income"/>
                    </div>
                </div>
            ))}
        </div>
    )
}

function FormattedNumber({value, suffix, className}) {
    return <NumberFormat
        thousandSeparator={" "}
        allowNegative={false}
        value={value}
        suffix={suffix}
        displayType="text"
        className={className}
    />

}

export default function FinancePage() {

    const [t] = useTranslation();

    const totalIncomeAction = useLoadAction(() => Api.totalIncome(dayjs().year()));

    if (totalIncomeAction.isLoading) {
        return <LoadingIndicator/>;
    }

    if (!totalIncomeAction.result) return null;
    const {jobs, incomeByMonth, totalIncome} = totalIncomeAction.result;

    return (<div className="finance-page page">
            <div className="container">

                <div className="finance-summary">
                    <span>{t('finance.totalIncome')}</span>
                    <FormattedNumber value={totalIncome} suffix={" Ft"} className="total-income"/>
                </div>

                <IncomeByMonthComp incomeByMonth={incomeByMonth}/>


                <JobsList jobs={jobs}/>
            </div>
        </div>
    )
}