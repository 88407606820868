import React, {useEffect, useState} from 'react';


function StaticPage({name}) {
    const [content,setContent] = useState("");

    useEffect(() => {
        fetch(`/staticpages/${name}`)
            .then((res) => res.text())
            .then(html => setContent(html))
    },[])
    return (
        <div dangerouslySetInnerHTML={{__html:content}}>

        </div>
    )
}


export function TermsAndConditions() {
    return <StaticPage name="termsAndConditions"/>
}


export  function PrivacyPolicy() {
    return <StaticPage name="privacypolicy"/>
}

export  function FAQ() {
    return <StaticPage name="faq"/>
}


export  function Cookies() {
    return <StaticPage name="cookies"/>
}